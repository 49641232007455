<template>
    <div class="nebula-fab" :style="cssProps">
        <NebulaTitleTooltip
            v-if="this.tooltipText"
            :text="this.tooltipText"
            class="nebula-fab__tooltip"
        >
            <slot />
        </NebulaTitleTooltip>
        <slot v-else />
    </div>
</template>

<script>
import NebulaTitleTooltip from '@/components/Tooltip/NebulaTitleTooltip.vue';

import themingUtils from '@/utils/theming/themingUtils';

export default {
    name: 'NebulaFloatingActionButton',
    components: {
        NebulaTitleTooltip,
    },
    mixins: [themingUtils],
    props: {
        tooltipText: {
            type: String,
            default: null,
        },
    },
};

</script>

<style lang="stylus">
$fab-offset-default = 57px;

:root {
    --nebula-fab-bottom: $fab-offset-default;
    --nebula-fab-box-shadow: $nebula-shadow-200;
    --nebula-fab-left: auto;
    --nebula-fab-position: fixed;
    --nebula-fab-right: $fab-offset-default;
    --nebula-fab-top: auto;
}

.nebula-fab {
    box-shadow: var(--nebula-fab-box-shadow);
    position: var(--nebula-fab-position);
    right: var(--nebula-fab-right);
    bottom: var(--nebula-fab-bottom);
    top: var(--nebula-fab-top);
    left: var(--nebula-fab-left);
    z-index: 100;

    &__tooltip {
        --nebula-tooltip-offset: $nebula-space-half;
    }
}
</style>
