<template>
    <FocusTrap
        :active="focusTrapActive"
        :clickOutsideDeactivates="true"
        :escapeDeactivates="true"
        :initialFocus="getInitialFocus"
        ref="PanelFocusTrap"
    >
        <div
            :class="viewClass"
            :style="cssProps"
        >
            <transition name="nebula-panel-overlay-transition">
                <div
                    v-if="useOverlay && isOpen"
                    class="nebula-panel__overlay"
                    @click="handleClose"
                />
            </transition>
            <div
                v-if="useOverlay"
                class="nebula-panel-overlay__wrapper"
            >
                <component
                    :is="panelElement"
                    :class="stateClass"
                    :aria-expanded="isOpen ? 'true' : 'false'"
                    :aria-label="getAriaLabel"
                    aria-modal="true"
                    :id="panelId"
                    ref="panel"
                    role="dialog"
                    :tabindex="isOpen ? 0 : undefined"
                    @keydown="handleKeydown"
                >
                    <transition name="nebula-panel-overlay-transition">
                        <div class="nebula-panel__top">
                            <div class="nebula-panel__header">
                                <slot name="panelHeader" />
                            </div>
                            <NebulaButton
                                :aria-label="$t('Close Panel')"
                                class="nebula-panel__close"
                                iconLeft="x"
                                :is-disabled="disableClose"
                                ref="closePanelOverlay"
                                size="s"
                                type="flat"
                                @click="handleClose"
                            />
                        </div>
                    </transition>
                    <transition name="nebula-panel-overlay-transition">
                        <div class="nebula-panel__bottom">
                            <slot name="panelContent" />
                        </div>
                    </transition>
                </component>
            </div>
            <div v-else-if="!useOverlay">
                <component
                    :is="panelElement"
                    :class="stateClass"
                    :aria-expanded="isOpen ? 'true' : 'false'"
                    :aria-label="getAriaLabel"
                    aria-modal="true"
                    :id="panelId"
                    ref="panel"
                    role="dialog"
                    :tabindex="isOpen ? 0 : undefined"
                    @keydown="handleKeydown"
                >
                    <transition name="nebula-panel-content-transition">
                        <div v-if="isVisible" class="nebula-panel__top">
                            <div class="nebula-panel__header">
                                <slot name="panelHeader" />
                            </div>
                            <NebulaButton
                                :aria-label="$t('Close Panel')"
                                class="nebula-panel__close"
                                iconLeft="x"
                                :is-disabled="disableClose"
                                size="s"
                                type="flat"
                                ref="closePanel"
                                @click="handleClose"
                            />
                        </div>
                    </transition>
                    <transition name="nebula-panel-content-transition">
                        <div v-if="isVisible" class="nebula-panel__bottom">
                            <slot name="panelContent" />
                        </div>
                    </transition>
                </component>
            </div>
        </div>
    </FocusTrap>
</template>

<script>
import { throttle } from 'lodash-es';
import themingUtils from '@/utils/theming/themingUtils';
import { FocusTrap } from 'focus-trap-vue';
import NebulaButton from '@/components/Button/NebulaButton.vue';

export default {
    name: 'NebulaPanel',
    components: {
        FocusTrap,
        NebulaButton,
    },
    mixins: [themingUtils],
    data() {
        return {
            isVisible: false,
            useOverlay: false,
            windowSizeBelowMobileThreshold: false,
            windowSize: null,
        };
    },
    props: {
        alwaysUseOverlay: {
            type: Boolean,
            default: false,
        },
        ariaLabel: {
            type: String,
            default: null,
        },
        displayOnRight: {
            type: Boolean,
            default: false,
        },
        firstFocusId: {
            type: String,
            default: null,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
        disableClose: {
            type: Boolean,
            default: false,
        },
        mobileThreshold: {
            type: Number,
            default: 1024,
        },
        panelElement: {
            type: String,
            default: 'section',
            enum: ['section', 'div', 'nav', 'form'],
        },
        panelId: {
            type: String,
            default: 'nebula-panel',
        },
    },
    emits: ['toggle-panel'],
    methods: {
        determineFocusedElement() {
            const focusable = this.getFocusableElements();

            if (focusable.length) {
                if (focusable.length > 1 && focusable[0].classList.contains('nebula-panel__close')) {
                    return focusable[1];
                }
                return focusable[0];
            }
            return this.$refs.panel;
        },
        getFocusableElements() {
            const focusableSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
            return this.$refs.panel.querySelectorAll(focusableSelector);
        },
        getInitialFocus() {
            let firstFocused = null;

            if (this.firstFocusId) {
                firstFocused = document.getElementById(this.firstFocusId);
            } else {
                firstFocused = this.determineFocusedElement();
            }

            return firstFocused;
        },
        handleClose() {
            this.$emit('toggle-panel', false);
        },
        handleKeydown(e) {
            // NebulaPanels with forms inside them shouldn't close if they'e being edited
            if (e.code === 'Escape' && !this.disableClose) {
                this.handleClose();
            }
        },
        setWindowSize() {
            this.windowSize = document.documentElement.clientWidth;
            this.windowSizeBelowMobileThreshold = this.windowSize <= this.mobileThreshold;
        },
    },
    computed: {
        focusTrapActive() {
            // isVisible state must be truthy (bound to transition timing on panel)
            return this.isVisible;
        },
        getAriaLabel() {
            return this.ariaLabel ? this.ariaLabel : this.$t('Panel');
        },
        stateClass() {
            return [
                'nebula-panel',
                {
                    'nebula-panel--open': this.isOpen,
                },
            ];
        },
        viewClass() {
            return [
                'nebula-panel__outer',
                {
                    'nebula-panel__outer--overlay': this.useOverlay,
                    'nebula-panel__outer--overlay-display-on-right': this.useOverlay && this.displayOnRight,
                },
            ];
        },
    },
    watch: {
        isOpen(newVal) {
            if (newVal) {
                // Delay setting isVisible to true until after slide in animation completes
                // so that FocusTrap can find the panel's contents
                setTimeout(() => {
                    this.isVisible = true;
                }, 125);

                if (this.useOverlay) {
                    document.body.classList.add('nebula-panel--body-overflow-hidden');
                } else {
                    document.body.classList.remove('nebula-panel--body-overflow-hidden');
                }
            } else {
                this.isVisible = false;
                document.body.classList.remove('nebula-panel--body-overflow-hidden');
            }
        },
        windowSize() {
            if (this.windowSizeBelowMobileThreshold || this.alwaysUseOverlay) {
                this.useOverlay = true;
                if (this.isOpen) {
                    document.body.classList.add('nebula-panel--body-overflow-hidden');
                }
            } else {
                this.useOverlay = false;
                if (this.isOpen) {
                    document.body.classList.remove('nebula-panel--body-overflow-hidden');
                }
            }
        },
    },
    mounted() {
        this.throttledHandleResize = throttle(this.setWindowSize, 150);
        window.addEventListener('resize', this.throttledHandleResize);

        this.setWindowSize();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.throttledHandleResize);
        document.body.classList.remove('nebula-panel--body-overflow-hidden');
    },
};
</script>

<style lang="stylus">

:root {
    --nebula-panel-background-color: $nebula-color-white;
    --nebula-panel-overlay-color: rgba(30, 30, 30, 0.5);
    --nebula-panel-padding: $nebula-space-3x;
    --nebula-panel-transition: $nebula-transition-default;
    --nebula-panel-width: 100%;

    @media (min-width: $nebula-breakpoints-mobile-landscape) {
        --nebula-panel-width: 350px;
    }
}

.nebula-panel__outer--overlay {
    .nebula-panel {
        inset-inline-start: calc(var(--nebula-panel-width) * -1);
        position: fixed;
        top: 0;
        transition: all var(--nebula-panel-transition);
        z-index: 4;

        &--open {
            inset-inline-start: 0;
        }

        &__overlay {
            background-color: var(--nebula-panel-overlay-color);
            height: 100%;
            inset-inline-start: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 2;
        }
    }

    &-display-on-right {
        .nebula-panel {
            inset-inline-end: calc(var(--nebula-panel-width) * -1);
            inset-inline-start: auto;

            &--open {
                inset-inline-end: 0;
            }
        }
    }
}

.nebula-panel {
    background-color: var(--nebula-panel-background-color);
    overflow: hidden;
    transition: all var(--nebula-panel-transition);
    width: 0;

    &::-webkit-scrollbar {
        display: none;
    }

    &--body-overflow-hidden {
        overflow: hidden;
    }

    &--open {
        height: calc(100vh);
        overflow-y: auto;
        width: var(--nebula-panel-width);
    }

    &__top {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-bottom: 0;
        padding-top: var(--nebula-panel-padding);
        padding-inline: var(--nebula-panel-padding);
    }

    &__header {
        nebula-text-body-1();
        nebula-text-semibold();
        color: $nebula-color-platform-neutral-900;
    }

    &__bottom {
        padding: var(--nebula-panel-padding);
    }
}

.nebula-panel-overlay-transition-enter-active,
.nebula-panel-overlay-transition-leave-active,
.nebula-panel-content-transition-enter-active {
    transition: opacity var(--nebula-panel-transition);
}

.nebula-panel-overlay-transition-enter,
.nebula-panel-overlay-transition-leave-to,
.nebula-panel-content-transition-enter {
    opacity: 0;
}

</style>
