import nebulaIconIds from '@discoveryedu/nebula-icons/icons.json';

let flattenedIcons = [];
Object.keys(nebulaIconIds).forEach((key) => {
    flattenedIcons = flattenedIcons.concat(nebulaIconIds[key]);
});

export default function nebulaIconNames() {
    return flattenedIcons;
}
