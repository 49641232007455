<template>
    <div class="nebula-rtl-view-switch">
        <NebulaFieldGroup
            :inputId="formattedId"
            labelText="Switch to RTL"
            labelPosition="inline"
            responsiveLabelText="RTL"
        >
            <template v-slot:formElement>
                <NebulaSwitch isCompressed>
                    <template v-slot:checkbox>
                        <input
                            class="nebula-switch__input"
                            :id="formattedId"
                            @input="toggleSwitch"
                            ref="rtlToggle"
                            type="checkbox"
                        >
                    </template>
                </NebulaSwitch>
            </template>
        </NebulaFieldGroup>
        <slot />
    </div>
</template>

<script>
import { randomStringId } from '@/utils/randomString';
import { NebulaFieldGroup, NebulaSwitch } from '@/components/FormElements/index';

export default {
    name: 'RtlViewSwitch',
    components: {
        NebulaSwitch,
        NebulaFieldGroup,
    },
    data() {
        return {
            right: false,
        };
    },
    props: {
        selector: {
            type: String,
            default: null,
        },
    },
    emits: ['toggle-rtl-switch'],
    computed: {
        formattedId() {
            return randomStringId('rtl-switch');
        },
    },
    methods: {
        toggleSwitch() {
            let selector;

            if (this.selector) {
                selector = this.selector;
            } else if (document.getElementsByClassName('doc-site-product-wrapper--header-nav').length > 0) {
                selector = '.doc-site-product-inner--header-nav';
            } else {
                selector = '.doc-site-product-inner';
            }

            const parent = document.querySelector(`${selector}`);

            if (!this.$data.right) {
                parent.setAttribute('dir', 'rtl');
            } else {
                parent.removeAttribute('dir');
            }

            this.$data.right = !this.$data.right;

            this.$emit('toggle-rtl-switch');
        },
    },
};
</script>

<style lang="stylus">
</style>
