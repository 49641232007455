<template>
    <div class="nebula-header-nav__wrapper">
        <NebulaIconButton
            v-if="!panelOpen"
            aria-label="Show list"
            aria-controls="header-nav-panel"
            :aria-expanded="panelOpen.toString()"
            class="nebula-header-nav__icon-button"
            icon="list"
            size="s"
            :tooltipProps="{
                position: 'inline-start',
                text: 'Section List'
            }"
            @click="handleToggle"
        />
        <NebulaPanel
            aria-label="Sections List"
            class="nebula-header-nav"
            panelId="header-nav-panel"
            @toggle-panel="handleToggle"
            :isOpen="panelOpen"
            displayOnRight
            panelElement="nav"
        >
            <template #panelHeader>
                <h3 class="nebula-header-nav__header">Jump to section</h3>
            </template>
            <template #panelContent>
                <ol class="nebula-header-nav__list">
                    <li
                        class="nebula-header-nav__list-item"
                        v-for="(section, index) in headerHierarchyArray"
                        :key="index"
                    >
                        <button
                            class="nebula-header-nav__button"
                            @click="(ev) => handleNavClick(section.h2element.id, ev)"
                            v-text="section.h2element.innerText"
                        />
                        <ul
                            v-if="section.h3elements"
                            class="nebula-header-nav__sub-list"
                        >
                            <li
                                class="nebula-header-nav__sub-list-item"
                                v-for="h3el in section.h3elements"
                                :key="h3el.id"
                            >
                                <button
                                    class="nebula-header-nav__button"
                                    @click="(ev) => handleNavClick(h3el.id, ev)"
                                    v-text="h3el.innerText"
                                />
                            </li>
                        </ul>
                    </li>
                </ol>
            </template>
        </NebulaPanel>
    </div>
</template>

<script>

import NebulaIconButton from '@/components/Button/NebulaIconButton.vue';
import NebulaPanel from '@/components/Panel/NebulaPanel.vue';

export default {
    name: 'HeaderNav',
    components: {
        NebulaIconButton,
        NebulaPanel,
    },
    data() {
        return {
            headers: null,
            headerHierarchyArray: [],
            panelOpen: false,
        };
    },
    computed: {
        docsMainEl() {
            return document.getElementById('docs-main');
        },
    },
    methods: {
        validH2(h2element) {
            let isValid = false;
            /* eslint-disable prefer-destructuring */
            const classList = h2element.classList;
            /* eslint-enable prefer-destructuring */

            if (classList.length === 0) {
                isValid = true;
            }

            return isValid;
        },
        getValidH3s(h2element) {
            const validH3s = [];

            // HeaderNav the expects each section or div to contain one h2 and any number of h3s
            // Do not include more than one h2 in each div or section
            // Section is preferred but div is included as a fallback

            const allH3s = h2element.closest('section, div').querySelectorAll('h3');

            // HeaderNav displays all h2s and h3s without a class
            // Do not add classless h2s and h3s to examples or
            // they will get dynamic IDs and will appear in HeaderNav
            // Add nebula-header-nav--ignore if necessary

            if (allH3s) {
                allH3s.forEach((h3) => {
                    /* eslint-disable prefer-destructuring */
                    const classList = h3.classList;
                    /* eslint-enable prefer-destructuring */

                    if (classList.length === 0) {
                        validH3s.push(h3);
                    }

                    classList.forEach((classname) => {
                        if (classname.includes('nebula-doc-row__header')) {
                            validH3s.push(h3);
                        }
                    });
                });
            }

            return validH3s;
        },
        handleNavClick(id, ev) {
            ev.preventDefault();
            this.docsMainEl.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth' });
        },
        handleToggle() {
            this.panelOpen = !this.panelOpen;
        },
    },
    mounted() {
        const hierarchyArray = [];

        setTimeout(() => {
            const h2Elements = this.docsMainEl.querySelectorAll('h2');

            h2Elements.forEach((h2element) => {
                if (this.validH2(h2element)) {
                    let h2Obj = {};
                    const h3elements = this.getValidH3s(h2element);

                    h2Obj = { h2element, h3elements };

                    hierarchyArray.push(h2Obj);
                }
            });
            this.headerHierarchyArray = hierarchyArray;
        }, 25);
    },
};

</script>

<style lang="stylus">

.nebula-header-nav {
    --nebula-panel-background-color: $nebula-color-platform-interface-200;
    --nebula-panel-padding: $nebula-space-1x;
    --nebula-panel-width: 260px;
    inset-block-start: 0;
    inset-inline-end: 0;
    height: calc(100vh);
    max-height: calc(100vh);
    overflow-y: auto;
    position: sticky;

    &__wrapper {
        background-color: $nebula-color-platform-interface-200;
        padding: $nebula-space-1x;
    }

    &__icon-button {
        inset-inline-end: $nebula-space-2x;
        margin: $nebula-space-1x;
        position: sticky;
        top: $nebula-space-2x;
        z-index: 2;
    }

    .nebula-panel {
        padding-block-start: 56px;

        @media (min-width: $nebula-breakpoints-tablet-landscape) {
            padding-block-start: 0;
        }
    }

    &__top {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: $nebula-space-2x;
    }

    &__header {
        margin-block: 0;
        margin-inline: 0 $nebula-space-1x;
    }

    &__list {
        margin: $nebula-space-1x;
        padding: 0;
    }

    &__sub-list-item,
    &__list-item {
        nebula-text-caption();
        margin: 0 0 $nebula-space-2x $nebula-space-2x;
        text-align: start;
    }

    &__sub-list {
        padding-inline: 0;
    }

    &__sub-list-item {
        margin: $nebula-space-1x 0 $nebula-space-1x $nebula-space-2x;
    }

    &__button {
        nebula-text-caption();
        nebula-text-link();

        background-color: transparent;
        border: 0;
        cursor: pointer;
        text-align: start;
    }
}

</style>
