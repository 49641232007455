/* eslint-disable import/prefer-default-export */

function randomStringId(propValue) {
    if (propValue) {
        // truncating the passed id here in case it's very long
        return propValue.substring(0, 63);
    }
    return Math.random().toString(36).substring(2, 10);
}

export { randomStringId };
