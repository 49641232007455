<template>
    <div
        class="nebula-popover__container"
        :id="getPopoverContainerId"
        :style="cssProps"
    >
        <slot name="trigger" :togglePopover="togglePopover" :popoverOpen="popoverOpen" />
        <div
            v-show="popoverOpen"
            :aria-hidden="!popoverOpen"
            :aria-label="ariaLabel"
            :aria-labelledby="getContentAriaLabelledBy"
            :class="popoverClassName"
            :id="popperId"
            ref="focusTrap"
            :role="isMenu ? 'menu' : role"
        >
            <div
                v-if="!hidePointerNub"
                class="popper__arrow"
                data-popper-arrow
            />
            <div
                :id="getFocusAreaId"
                class="nebula-popover__inner"
            >
                <div
                    v-if="showCloseButton"
                    class="nebula-popover__close-button-container"
                >
                    <NebulaButton
                        @click="closePopover"
                        class="nebula-popover__close-button"
                        icon-left="x"
                        type="flat"
                        size="s"
                        :aria-label="$t('close popover')"
                    />
                </div>
                <template v-if="popoverOpen">
                    <div class="nebula-popover__content-container">
                        <slot name="content" :togglePopover="togglePopover" :popoverOpen="popoverOpen" :closePopover="closePopover" />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>

import themingUtils from '@/utils/theming/themingUtils';
import { createFocusTrap } from 'focus-trap';
import { createPopper } from '@popperjs/core';
import NebulaButton from '@/components/Button/NebulaButton.vue';

export default {
    name: 'NebulaPopover',
    components: {
        NebulaButton,
    },
    mixins: [themingUtils],
    data() {
        return {
            button: null,
            focusTrap: {},
            popoverOpen: false,
            popperInstance: null,
            popover: null,
            useFocusTrap: false,
        };
    },
    props: {
        ariaLabel: {
            type: String,
            default: null,
        },
        ariaLabelledby: {
            type: String,
            default: null,
        },
        boundariesSelector: {
            type: [String, Number],
            default: null,
        },
        buttonId: {
            type: [String, Number],
            default: null,
        },
        distance: {
            type: Number,
            default: 10,
        },
        id: {
            type: [String, Number],
            default: null,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isMenu: {
            type: Boolean,
            default: false,
        },
        firstFocusId: {
            type: [String, Number],
            default: null,
        },
        focusTrapEnabled: {
            type: Boolean,
            default: true,
        },
        hidePointerNub: {
            type: Boolean,
            default: false,
        },
        placement: {
            type: String,
            default: 'bottom', // option: top, right, bottom, left
            enum: ['top', 'right', 'bottom', 'left'], // for docs site
        },
        positionOverrideElementId: {
            type: String,
            default: null,
        },
        role: {
            type: String,
            default: 'complementary',
        },
        showCloseButton: {
            type: Boolean,
            default: false,
        },
        skidding: {
            type: Number,
            default: 0,
        },
        useFixedStrategy: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        focusTrapArea() {
            return document.getElementById(this.getFocusAreaId);
        },
        focusableSelector() {
            return 'button, [href], input, select, textarea, details, [tabindex]:not([tabindex="-1"])';
        },
        getContentAriaLabelledBy() {
            let ariaLabelledby = null;

            if (this.ariaLabelledby) {
                ariaLabelledby = this.ariaLabelledby;
            } else if (this.buttonId && !this.ariaLabelledby && !this.ariaLabel) {
                ariaLabelledby = this.buttonId;
            }

            return ariaLabelledby;
        },
        getFocusAreaId() {
            return `nebula-popover__focus-area-${this.id}`;
        },
        getInitialFocus() {
            let firstFocused = null;

            if (this.firstFocusId) {
                firstFocused = document.getElementById(this.firstFocusId);
            } else {
                firstFocused = this.getFirstSelectableElement();
            }

            return firstFocused;
        },
        getPlacement() {
            const optionObject = {
                placement: this.placement,
            };
            return optionObject;
        },
        getPopoverContainerId() {
            return `nebula-popover__container-${this.id}`;
        },
        getTriggerButtonId() {
            return `nebula-popover-trigger__${this.id}`;
        },
        popoverClassName() {
            const className = [
                'nebula-popover',
                'popper',
                {
                    'nebula-popover--has-close-button': this.showCloseButton,
                },
            ];
            return className;
        },
        popperId() {
            return this.id;
        },
        triggerButtonElement() {
            return document.getElementById(this.getTriggerButtonId);
        },
    },
    methods: {
        closePopover() {
            if (this.popoverOpen) {
                this.popoverOpen = false;
                if (this.useFocusTrap) {
                    this.focusTrap.deactivate();
                }
                if (this.useFocusTrap || this.getFocusableElements().length === 1) {
                    // After closing the popover, set focus back to the trigger
                    setTimeout(() => {
                        document.getElementById(this.getTriggerButtonId).focus();
                    }, 100);
                }
            }
        },
        closePopoverOnTab() {
            if (this.popoverOpen) {
                this.popoverOpen = false;
                document.removeEventListener('keydown', this.tabKeyOverride);
            }
        },
        emitKeyDownEvent(e) {
            if (e.key === 'Escape') {
                this.closePopover();
            }

            if (e.key === 'Tab' && this.getFocusableElements().length === 0 && !this.isMenu) {
                this.closePopover();
            }
        },
        enableFocusTrap() {
            // After the Popover is shown, create the FocusTrap.
            if (this.isMenu) {
                // FocusTrap uses `checkCanFocusTrap` to makes sure the popover is there and contains focusable elements
                this.focusTrap = createFocusTrap(this.focusTrapArea, {
                    checkCanFocusTrap: (trapContainers) => {
                        /* eslint-disable arrow-body-style */
                        const results = trapContainers.map((trapContainer) => (
                            new Promise((resolve) => {
                                const interval = setInterval(() => {
                                    if (getComputedStyle(trapContainer).visibility !== 'hidden') {
                                        resolve();
                                        clearInterval(interval);
                                    }
                                }, 5);
                            })
                        ));
                        // Return a promise that resolves when all the trap containers are able to receive focus
                        return Promise.all(results);
                    },
                    isKeyForward: (event) => event.key === 'ArrowDown',
                    isKeyBackward: (event) => event.key === 'ArrowUp',
                    onPostActivate: () => document.addEventListener('keydown', this.focusTrapMenuKeyboardOverrides),
                    onPostDeactivate: () => document.removeEventListener('keydown', this.focusTrapMenuKeyboardOverrides),
                    clickOutsideDeactivates: true,
                    fallbackFocus: this.getInitialFocus,
                    initialFocus: this.getInitialFocus,
                    preventScroll: true,

                });
            } else {
                this.focusTrap = createFocusTrap(this.focusTrapArea, {
                    checkCanFocusTrap: (trapContainers) => {
                        /* eslint-disable arrow-body-style */
                        const results = trapContainers.map((trapContainer) => (
                            new Promise((resolve) => {
                                const interval = setInterval(() => {
                                    if (getComputedStyle(trapContainer).visibility !== 'hidden') {
                                        resolve();
                                        clearInterval(interval);
                                    }
                                }, 5);
                            })
                        ));
                        return Promise.all(results);
                    },
                    clickOutsideDeactivates: true,
                    fallbackFocus: this.getInitialFocus,
                    initialFocus: this.getInitialFocus,
                    preventScroll: true,
                });
            }

            this.focusTrap.activate();
        },
        tabKeyOverride(event) {
            if (event.key === 'Tab') {
                this.closePopoverOnTab();
            }
        },
        focusTrapMenuKeyboardOverrides(event) {
            if (event.key === 'Tab') {
                this.closePopover();
            }

            if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                event.preventDefault();
            }
        },
        getFirstSelectableElement() {
            const focusable = this.getFocusableElements();
            if (focusable.length) {
                if (focusable.length > 1 && focusable[0].classList.contains('nebula-popover__close-button')) {
                    return focusable[1];
                }
                return focusable[0];
            }
            return this.$refs.focusTrap;
        },
        getFocusableElements() {
            return this.$refs.focusTrap.querySelectorAll(this.focusableSelector);
        },
        onBodyClick({ target }) {
            // Runs on every click on the page, for each popover instance
            // `closePopover()` if the click target is not inside a popover element
            if (!this.$el.contains(target)) {
                this.closePopover();
            }
        },
        setFocusTrapIsAvailable() {
            if (this.focusTrapEnabled && this.getFocusableElements().length > 1) {
                this.useFocusTrap = true;
            } else {
                // if there is only 1 focusable item, do no use focus trap
                // it causes focus to be locked on the focusable item while tabbing
                this.useFocusTrap = false;
            }
        },
        togglePopover() {
            if (this.isDisabled) {
                return;
            }
            if (!this.popoverOpen) {
                this.popoverOpen = true;
                this.popperInstance.setOptions((options) => ({
                    ...options,
                    modifiers: [
                        ...options.modifiers,
                        { name: 'eventListeners', enabled: true },
                    ],
                }));
                this.popover.setAttribute('data-show', '');
                this.$nextTick(() => {
                    this.popperInstance.update();
                });
                // FocusTrap needs a moment to find the Popover in the DOM
                setTimeout(() => {
                    this.setFocusTrapIsAvailable();
                    if (this.useFocusTrap) {
                        this.enableFocusTrap();
                    }
                    if (this.getFocusableElements().length === 1) {
                        // when there is only one focusable element in the popover
                        const focusable = this.getFocusableElements();
                        // put focus on the focusable element
                        focusable[0].focus();
                        // add an event listener that will listen to the tab key
                        // tab key override closes the popover and removes the event listener
                        document.addEventListener('keydown', this.tabKeyOverride);
                    }
                }, 100);
            } else {
                // Disable the event listeners
                this.popperInstance.setOptions((options) => ({
                    ...options,
                    modifiers: [
                        ...options.modifiers,
                        { name: 'eventListeners', enabled: false },
                    ],
                }));
                this.popover.removeAttribute('data-show');
                this.popoverOpen = false;
            }
        },
    },
    mounted() {
        this.button = this.triggerButtonElement;
        this.popover = document.getElementById(this.popperId);

        this.button.addEventListener('click', this.togglePopover);

        let options = {
            modifiers: [
                {
                    name: 'eventListeners',
                    enabled: false,
                },
                {
                    name: 'offset',
                    options: {
                        offset: [this.skidding, this.distance],
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        boundary: document.querySelector(this.boundariesSelector),
                    },
                },
            ],
            placement: this.placement,
        };

        if (this.useFixedStrategy) {
            options = {
                ...options,
                strategy: 'fixed',
            };
        }

        this.popperInstance = createPopper(this.button, this.popover, options);

        document.addEventListener('click', this.onBodyClick);
        document.addEventListener('keydown', this.emitKeyDownEvent);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.onBodyClick);
        document.removeEventListener('keydown', this.emitKeyDownEvent);
    },
};

</script>

<style lang="stylus">
:root {
    --nebula-popover-arrow-border-color: $nebula-color-neutral-300;
    --nebula-popover-background-color: $nebula-color-white;
    --nebula-popover-border-radius: $nebula-border-radius-default;
    --nebula-popover-border: 1px solid $nebula-color-neutral-300;
    --nebula-popover-box-shadow: $nebula-shadow-300;
    --nebula-popover-close-button-icon-fill: $nebula-color-platform-interactive-850;
    --nebula-popover-font-size: $nebula-font-size-body-2;
    --nebula-popover-font-weight: 400;
    --nebula-popover-padding: $nebula-space-3x;
    --nebula-popover-text-color: $nebula-color-interface-blue-500;
    --nebula-popover-z-index: 7;

    --nebula-popover-menu-content-gap: $nebula-space-half;
    --nebula-popover-menu-content-max-height: none;
    --nebula-popover-menu-content-max-width: none;
    --nebula-popover-menu-content-min-width: none;
    --nebula-popover-menu-content-padding: $nebula-space-1x;
}

.nebula-popover__container {
    position: relative;
}

.nebula-popover {
    height: max-content;
    width: max-content;
    z-index: var(--nebula-popover-z-index);

    &__inner {
        nebula-text-body-2();
        background-color: var(--nebula-popover-background-color);
        border-radius: var(--nebula-popover-border-radius);
        border: var(--nebula-popover-border);
        box-shadow: var(--nebula-popover-box-shadow);
        color: var(--nebula-popover-text-color);
        font-size: var(--nebula-popover-font-size);
        font-weight: var(--nebula-popover-font-weight);
        padding: var(--nebula-popover-padding);
        position: relative;
        text-align: inherit;
    }

    .popper__arrow  {
        height: $nebula-space-2x;
        position: absolute;
        width: $nebula-space-2x;
        z-index: 1;

        &::before {
            content: "";
            background-color: var(--nebula-popover-background-color);
            border-color: var(--nebula-popover-arrow-border-color);
            border-style: solid;
            height: $nebula-space-2x;
            margin: 0;
            position: absolute;
            transform: rotate(45deg);
            width: $nebula-space-2x;
            z-index: 1;
        }
    }

    &-menu {
        &__content {
            display: flex;
            flex-direction: column;
            gap: var(--nebula-popover-menu-content-gap);
            list-style: none;
            margin: 0;
            max-height: var(--nebula-popover-menu-content-max-height);
            max-width: var(--nebula-popover-menu-content-max-width);
            min-width: var(--nebula-popover-menu-content-min-width);
            overflow: hidden;
            overflow-y: auto;
            padding: var(--nebula-popover-menu-content-padding);
            position: relative;
            inset-inline-end: 0;
            z-index: 1;
        }
    }

    &[data-popper-placement^="top"] {
        .popper__arrow {
            inset-block-start: calc(100% - 8px);

            &::before {
                border-block-width: 0 1px;
                border-inline-width: 0 1px;
            }
        }
    }

    &[data-popper-placement^="right"] {
        .popper__arrow {
            inset-inline-end: calc(100% - 8px);

            &::before {
                border-block-width: 0 1px;
                border-inline-width: 1px 0;
            }
        }
    }

    &[data-popper-placement^="bottom"] {
        .popper__arrow {
            inset-block-start: -8px;

            &::before {
                border-block-width: 1px 0;
                border-inline-width: 1px 0;
            }
        }
    }

    &[data-popper-placement^="left"] {
        .popper__arrow {
            inset-inline-start: calc(100% - 8px);

            &::before {
                border-block-width: 1px 0;
                border-inline-width: 0 1px;
            }
        }
    }

    &__close-button {
        margin-inline-start: calc(100% - 32px);

        .nebula-button__icon {
            fill: var(--nebula-popover-close-button-icon-fill);
            height: $nebula-space-2x;
            width: $nebula-space-2x;
        }
    }
}

[dir="rtl"] {
    .nebula-popover {
        &[data-popper-placement^="top"] {
            .popper__arrow {

                &::before {
                    border-block-width: 0 1px;
                    border-inline-width: 1px 0;
                }
            }
        }

        &[data-popper-placement^="left"] {
            .popper__arrow {
                inset-inline-end: calc(100% - 8px);
                inset-inline-start: auto;

                &::before {
                    border-block-width: 1px 0;
                    border-inline-width: 1px 0;
                }
            }
        }

        &[data-popper-placement^="right"] {
            .popper__arrow {
                inset-inline-end: auto;
                inset-inline-start: calc(100% - 8px);

                &::before {
                    border-block-width: 0 1px;
                    border-inline-width: 0 1px;
                }
            }
        }

        &[data-popper-placement^="bottom"] {
            .popper__arrow {

                &::before {
                    border-block-width: 1px 0;
                    border-inline-width: 0 1px;
                }
            }
        }
    }
}

</style>
