<template>
    <div :class="wrapperClass">
        <div
            v-if="useHeaderNav"
            class="doc-site-product-inner"
        >
            <div class="doc-site-product-inner--header-nav">
                <slot />
            </div>
            <HeaderNav />
        </div>
        <div
            v-else
            class="doc-site-product-inner"
        >
            <slot />
        </div>
        <transition name="nebula-back-to-top-transition">
            <div v-if="showBackToTop">
                <NebulaBackToTop />
            </div>
        </transition>
    </div>
</template>

<script>

import { throttle } from 'lodash-es';
import NebulaBackToTop from '@/components/Button/NebulaBackToTop.vue';
import HeaderNav from '@/doc-components/HeaderNav.vue';

export default {
    name: 'PageWrapper',
    components: {
        NebulaBackToTop,
        HeaderNav,
    },
    data() {
        return {
            showBackToTop: false,
        };
    },
    computed: {
        wrapperClass() {
            const supportedDisplay = ['flex', 'block'];
            const { display } = this.$props;

            const className = [
                'doc-site-product-wrapper',
                {
                    'doc-site-product-wrapper--padded': this.$props.padded,
                    'doc-site-product-wrapper--header-nav': this.$props.useHeaderNav,
                },
            ];

            if (supportedDisplay.includes(this.$props.display)) {
                className.push(`doc-site-product-wrapper--${display}`);
            }

            return className;
        },
    },
    methods: {
        checkScroll() {
            if ((document.querySelector('#app').getBoundingClientRect().top * -1) > 300) {
                this.showBackToTop = true;
            } else {
                this.showBackToTop = false;
            }
        },
    },
    props: {
        padded: {
            type: Boolean,
            default: true,
        },
        display: {
            type: String,
            default: 'block', // Options: block, flex
        },
        useBackToTop: {
            type: Boolean,
            default: false,
        },
        useHeaderNav: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.useBackToTop) {
            this.checkScroll();

            this.throttledHandleScroll = throttle(this.checkScroll, 150);
            document.addEventListener('scroll', this.throttledHandleScroll);
        }
    },
    unmounted() {
        if (this.useBackToTop) {
            document.removeEventListener('scroll', this.throttledHandleScroll);
        }
    },
};
</script>

<style lang="stylus">

.doc-site-product-wrapper {
    &--padded {
        padding: $nebula-space-4x $nebula-space-3x;
    }

    &--flex {
        display: flex;
    }

    &--block {
        display: block;
    }

    &--header-nav {
        .doc-site-product-inner {
            display: flex;
            gap: $nebula-space-2x;
            justify-content: space-between;
        }
    }
}

.doc-site-product-wrapper--padded {
    &.doc-site-product-wrapper--header-nav {
        padding-block: 0 0;
        padding-inline: $nebula-space-3x 0;

        .doc-site-product-inner {
            .doc-site-product-inner--header-nav {
                max-width: 100%;
                width: calc(100vw - 130px);
            }

            > div:first-child {
                padding-block: $nebula-space-4x;
            }
        }
    }
}

.header-nav-visible {
    .doc-site-product-wrapper--padded {
        .doc-site-product-wrapper--header-nav {
            .doc-site-product-inner {
                .doc-site-product-inner--header-nav {
                    max-width: 100%;
                    width: calc(100vw - 450px);
                }
            }
        }
    }
}

.nebula-back-to-top-transition-enter-active,
.nebula-back-to-top-transition-leave-active,
.nebula-back-to-top-transition-enter-active {
    opacity: 1;
    transition: opacity $nebula-transition-default;
}

.nebula-back-to-top-transition-enter,
.nebula-back-to-top-transition-leave-to,
.nebula-back-to-top-transition-enter {
    opacity: 0;
}

</style>
