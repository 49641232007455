<template>
    <svg
        :class="className"
        aria-hidden="true"
        focusable="false"
    >
        <use :xlink:href="href" />
    </svg>
</template>

<script>

import nebulaSvg from '@discoveryedu/nebula-icons/nebula.svg';

export default {
    name: 'NebulaIcon',
    computed: {
        className() {
            const className = [
                'nebula-icon',
                `nebula-icon--${this.size}`,
            ];
            return this.classOverride ? this.classOverride : className;
        },
        href() {
            if (this.spritePath) {
                return `${this.spritePath}#${this.symbolId}`;
            }
            return this.$nebulaSVGPath ? `${this.$nebulaSVGPath}#${this.symbolId}` : `${nebulaSvg}#${this.symbolId}`;
        },
    },
    props: {
        classOverride: {
            required: false,
            type: [String, Object, Array],
        },
        size: {
            default: 'm', // Nebula icons default to 24px
            required: false,
            type: String,
        },
        spritePath: {
            default: null,
            type: String,
        },
        symbolId: {
            required: true,
            type: String,
        },
    },
};
</script>

<style lang="stylus">
    .nebula-icon--s {
        nebula-icon-s();
    }
    .nebula-icon--m {
        nebula-icon-m();
    }
    .nebula-icon--l {
        nebula-icon-l();
    }
    .nebula-icon--xl {
        nebula-icon-xl();
    }
    .nebula-icon--xxl {
        nebula-icon-xxl();
    }
</style>
