<template>
    <div :class="containerClassName">
        <NebulaLabel
            v-if="labelText"
            :labelText="labelText"
            :for="inputId"
            :responsiveLabelText="responsiveLabelText"
            :hideRequiredTextFromSR="hideRequiredTextFromSR"
            :isRequired="isRequired"
            :requiredLabelId="requiredLabelId"
            :requiredLabelText="requiredLabelText"
        />
        <div class="nebula-field-group__element-container">
            <slot name="formElement" />
        </div>
        <NebulaFormHelper
            v-show="helperText"
            :icon="helperIcon"
            :helperAlert="helperAlert"
            :helperId="getHelperId"
            :status="helperStatus"
            :text="helperText ? helperText : null"
        />
    </div>
</template>

<script>
import NebulaFormHelper from '@/components/FormElements/NebulaFormHelper.vue';
import NebulaLabel from '@/components/FormElements/NebulaLabel.vue';

export default {
    name: 'NebulaFieldGroup',
    components: {
        NebulaFormHelper,
        NebulaLabel,
    },
    props: {
        hideRequiredTextFromSR: {
            type: Boolean,
            default: false,
        },
        helperAlert: {
            type: Boolean,
            default: false,
        },
        helperIcon: {
            type: String,
            default: null,
        },
        helperStatus: {
            type: String,
            default: null,
        },
        helperText: {
            type: String,
            default: null,
        },
        inputId: {
            type: [String, Number],
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        requiredLabelId: {
            type: String,
            default: null,
        },
        requiredLabelText: {
            type: String,
            default: null,
        },
        labelPosition: {
            type: String,
            default: 'top', // Options: top, inline
            enum: ['top', 'inline'],
        },
        labelText: {
            type: String,
            default: null,
        },
        responsiveLabelText: {
            type: String,
            default: null,
        },
    },
    computed: {
        containerClassName() {
            const className = [
                'nebula-field-group',
                {
                    'nebula-field-group--label-top': this.labelText && this.labelPosition === 'top',
                    'nebula-field-group--label-inline': this.labelText && this.labelPosition === 'inline',
                    'nebula-field-group--label-and-helper': this.labelText && this.helperText,
                },
            ];
            return className;
        },
        getHelperId() {
            return `helper-text__${this.inputId}`;
        },
    },
};
</script>

<style lang="stylus">

.nebula-field-group {
    border: 0;
    margin: 0 0 $nebula-space-2x;
    max-width: 700px;
    padding: 0;

    .nebula-input,
    .nebula-select,
    .nebula-textarea,
    .nebula-vue-select {
        margin: 0;
    }
}

.nebula-field-group--label-inline {
    align-items: center;
    display: grid;
    grid-gap: 0 $nebula-space-1x;
    grid-template-columns: max-content 1fr;

    .nebula-label {
        margin-bottom: 0;
    }

    &.nebula-field-group--label-and-helper {
        .nebula-label {
            grid-column: 1/2;
            grid-row: 1;
        }

        .nebula-field-group__element-container {
            grid-column: 2;
            grid-row: 1;
        }

        .nebula-form-helper {
            grid-column: 2;
            grid-row: 2;
        }
    }
}

.nebula-field-group--horizontal {
    display: flex;
    gap: $nebula-space-3x;
    margin: 0 0 $nebula-space-2x 0;
    max-width: 700px;

    .nebula-field-group {
        margin-bottom: 0;
    }
}

.nebula-field-group--xs {
    max-width: $nebula-space-8x;
}

.nebula-field-group--s {
    max-width: 128px;
}

</style>
