import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            pageType: 'main',
        },
    },
    {
        path: '/accordion',
        name: 'Accordion',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/AccordionSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/action-bar-sink',
        name: 'Action Bar',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ActionBarSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/back-to-top-sink',
        name: 'Back To Top',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/BackToTopSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/badges-sink',
        name: 'Badge',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/BadgesSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/banner-sink',
        name: 'Banner',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/BannerSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/block-message-sink',
        name: 'Block Message',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/BlockMessageSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/breadcrumbs-sink',
        name: 'Breadcrumbs',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/BreadcrumbsSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/buttons-component-explorer',
        name: 'Buttons Component Explorer',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ButtonsComponentExplorer.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/button-group-sink',
        name: 'Button Group',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ButtonGroupSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/buttons-sink',
        name: 'Button',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ButtonsSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/call-to-action-sink',
        name: 'Call To Action',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/CallToActionSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/callout-sink',
        name: 'Callout',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/CalloutSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/cards-sink',
        name: 'Card',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/CardsSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/card-carousel-sink',
        name: 'Card Carousel',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/CardCarouselSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/carousel-sink',
        name: 'Carousel',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/CarouselSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/checkbox-sink',
        name: 'Checkbox',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/CheckboxSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/code-pair-sink',
        name: 'Code Pair',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/CodePairSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/color-sink',
        name: 'Color',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ColorSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/combobox-sink',
        name: 'Combobox',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ComboboxSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/content-row-sink',
        name: 'ContentRow',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ContentRowSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/copy-button-sink',
        name: 'Copy Button',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/CopyButtonSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/disabled-demo',
        name: 'Aria-disabled',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/DisabledDemo.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/do-dont-sink',
        name: 'Do/Dont',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/DoDontSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/datepicker-sink',
        name: 'Datepicker',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/DatepickerSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/dialog-sink',
        name: 'Dialog',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/DialogSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/dropdown-sink',
        name: 'Dropdown',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/DropdownSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/fab-sink',
        name: 'Floating Action Button',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/FloatingActionButtonSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/feature-row-sink',
        name: 'Feature Row',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/FeatureRowSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/form-element-examples',
        name: 'Form Element Examples',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/FormElementExamples.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/footer',
        name: 'Footer',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/Footer.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/footer-character-examples',
        name: 'Footer Character Examples',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/FooterCharacterExamples.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/fullscreen-sink',
        name: 'Fullscreen',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/FullscreenSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/global-nav',
        name: 'Global Nav',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/GlobalNavSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/grid-sink',
        name: 'Grid',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/GridSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/hero-sink',
        name: 'Hero',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/HeroSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/icon-sink',
        name: 'Icon',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/IconSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/icon-sidebar-sink',
        name: 'Icon Sidebar',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/IconSidebarSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/image-sink',
        name: 'Image',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ImageSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/image-example',
        name: 'Image Example',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ImageExampleSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/image-row-sink',
        name: 'Image Row',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ImageRowSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/input-sink',
        name: 'Input',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/InputSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/input-with-buttons-sink',
        name: 'Input with Buttons',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/InputWithButtonsSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/list-group-sink',
        name: 'List Group',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ListgroupSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/menu-bar-sink',
        name: 'Menu Bar',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/MenuBarSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/modal-sink',
        name: 'Modal',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ModalSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/multi-level-nav',
        name: 'MultiLevelNav',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/MultiLevelNavSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/page-shell',
        name: 'Page Shell',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/PageShellSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/page-shell/with-action-bar',
        name: 'Page Shell with Action Bar',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/PageShell/WithActionBar.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/page-shell/no-product-bar',
        name: 'Page Shell without Product Bar',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/PageShell/NoProductBar.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/page-shell-embed',
        name: 'Page Shell Embed',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/PageShellEmbedSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/pagination-sink',
        name: 'Pagination',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/PaginationSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/panel-sink',
        name: 'Panel',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/PanelSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/panel/always-use-overlay',
        name: 'Panel Always Use Overlay',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/Panel/AlwaysUseOverlay.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/panel/display-on-right',
        name: 'Panel Display On Right',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/Panel/DisplayOnRight.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/picture-sink',
        name: 'Picture',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/PictureSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/popover-sink',
        name: 'Popover',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/PopoverSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/product-bar-sink',
        name: 'Product Bar',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ProductBarSink.vue'),
        meta: {
            pageType: 'sink',
        },

    },
    {
        path: '/product-block-sink',
        name: 'Product Block',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ProductBlockSink.vue'),
        meta: {
            pageType: 'sink',
        },

    },
    {
        path: '/profile-panel-sink',
        name: 'Profile Panel',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ProfilePanelSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/quicklinks-sink',
        name: 'Quicklinks',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/QuicklinksSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/favorites-sink',
        name: 'Favorites',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/QuickListSink.vue'),
        meta: {
            pageType: 'sink',
        },

    },
    {
        path: '/radio-sink',
        name: 'Radio',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/RadioSink.vue'),
        meta: {
            pageType: 'sink',
        },

    },
    {
        path: '/required-form-elements',
        name: 'Required',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/RequiredFormElements.vue'),
        meta: {
            pageType: 'sink',
        },

    },
    {
        path: '/row-sink',
        name: 'Row',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/RowsSink.vue'),
        meta: {
            pageType: 'sink',
        },

    },
    {
        path: '/select-sink',
        name: 'Select',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/SelectSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/sidebar-sink',
        name: 'Sidebar',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/SidebarSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/switch-sink',
        name: 'Switch',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/SwitchSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/sticker-sink',
        name: 'Sticker',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/StickerSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/table-sink',
        name: 'Table',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/TableSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {

        path: '/tabs-sink',
        name: 'Tabs',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/TabsSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/tags-sink',
        name: 'Tags',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/TagsSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/testimonial-sink',
        name: 'Testimonial',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/TestimonialSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {

        path: '/textarea-sink',
        name: 'Textarea',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/TextareaSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {

        path: '/titleblock-sink',
        name: 'Title Block',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/TitleBlockSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/thumbnail-sink',
        name: 'Thumbnail',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ThumbnailSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/toast-message-sink',
        name: 'Toast Message',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/ToastMessageSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/tokens',
        name: 'Tokens',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/Tokens.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/tooltip-sink',
        name: 'Tooltip',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/TooltipSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/type-sink',
        name: 'Typography',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/TypographySink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
    {
        path: '/vue-select-sink',
        name: 'Vue Select',
        component: () => import(/* webpackChunkName: "sink-pages" */ '../views/VueSelectSink.vue'),
        meta: {
            pageType: 'sink',
        },
    },
];

const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

export default router;
export const sinkPages = routes.filter((route) => (route.meta && route.meta.pageType === 'sink'));
