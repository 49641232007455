<template>
    <component
        :is="menuItem ? 'div' : element"
        class="nebula-accordion"
        :class="modeClass"
        :style="cssProps"
    >
        <!--
            Accordion Menu Variation
            - Text header is a link, open accordion trigger is caret icon button
            - Driven by menuItem/menuBar data
        -->
        <div
            v-if="menuItem"
            class="nebula-accordion__menu-item"
        >
            <NebulaButton
                v-if="menuItem.url || menuItem.useRouterLink"
                :class="menuItem.selected && 'nebula-accordion__menu-item--selected'"
                :text="menuItem.name"
                size="s"
                type="flat"
                :link="menuItem.url ? menuItem.url : null"
                :routerLink="menuItem.useRouterLink ? menuItem.useRouterLink : null"
                v-bind="buttonDataAttributes"
            />
            <NebulaButton
                v-else
                :text="menuItem.name"
                size="s"
                type="flat"
                :aria-expanded="`${isExpanded}`"
                :aria-controls="formattedId"
                :aria-label="$t('expand menu')"
                @click="toggle()"
                @keydown="handleKeydown"
                v-bind="buttonDataAttributes"
            />
            <button
                v-if="showContent"
                :id="`${formattedId}-button`"
                :class="buttonClass"
                :aria-expanded="`${isExpanded}`"
                :aria-controls="formattedId"
                :aria-label="$t('expand menu')"
                @click="toggle()"
                @keydown="handleKeydown"
                type="button"
                v-bind="buttonDataAttributes"
            >
                <NebulaIcon size="s" symbolId="caret-down" class="nebula-accordion-caret" />
            </button>
        </div>

        <!-- Button containing text header opens accordion -->
        <div v-else>
            <button
                :id="`${formattedId}-button`"
                :class="buttonClass"
                :aria-expanded="`${isExpanded}`"
                :aria-controls="formattedId"
                @click="toggle()"
                @keydown="handleKeydown"
                type="button"
                v-bind="buttonDataAttributes"
            >
                <component
                    class="nebula-accordion__summary"
                    :is="accordionSummaryElement"
                >
                    <div v-if="$slots.header" class="nebula-accordion__header">
                        <NebulaIcon :symbolId="icon" v-if="icon" class="nebula-accordion-icon" />
                        <slot name="header" />
                    </div>
                    <span v-else class="nebula-accordion__title">
                        <NebulaIcon :symbolId="icon" v-if="icon" class="nebula-accordion-icon" />
                        {{ text }}
                    </span>
                    <NebulaIcon size="s" symbolId="caret-down" class="nebula-accordion-caret" />
                </component>
                <div v-if="$slots.preview" class="nebula-accordion__preview">
                    <slot name="preview" />
                </div>
            </button>
        </div>

        <!--
            Accordion Content
            - will be hidden if MenuItem with no subitems
        -->
        <div
            v-if="showContent"
            ref="contentEl"
            role="region"
            :class="contentClass"
            :id="formattedId"
            :aria-labelledby="`${formattedId}-button`"
            :style="{
                height: expandHeight,
            }"
        >
            <div
                ref="contentContainer"
                class="nebula-accordion__content-container"
            >
                <slot />
            </div>
        </div>
    </component>
</template>

<script>
import NebulaButton from '@/components/Button/NebulaButton.vue';
import NebulaIcon from '@/components/Icon/NebulaIcon.vue';
import { randomStringId } from '@/utils/randomString';
import themingUtils from '@/utils/theming/themingUtils';

export default {
    name: 'NebulaAccordion',
    components: {
        NebulaButton,
        NebulaIcon,
    },
    mixins: [themingUtils],
    data() {
        return {
            contentElAnimation: null,
            isExpanded: false,
            expandHeight: 0,
        };
    },
    props: {
        accordionSummaryElement: {
            type: String,
            default: 'div',
        },
        element: {
            type: String,
            default: 'section',
        },
        icon: {
            type: String,
        },
        id: {
            type: [String, Number],
        },
        isExpandedOnLoad: {
            type: Boolean,
            default: false,
        },
        menuItem: {
            type: [Array, Object],
            default: null,
        },
        mode: {
            default: 'outline',
            type: String,
        },
        text: {
            default: 'Accordion',
            type: String,
        },
        buttonDataAttributes: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['accordion-action'],
    mounted() {
        if (this.isExpandedOnLoad || (this.hasSubMenuItems && this.containsSelectedItem)) {
            this.isExpanded = true;
            this.expandHeight = 'auto';
        }
    },
    computed: {
        buttonClass() {
            const className = [
                'nebula-accordion__button',
                {
                    'nebula-accordion__button--expanded': this.isExpanded,
                },
            ];

            return className;
        },
        containsSelectedItem() {
            let containsSelectedItem = false;

            Object.keys(this.menuItem.items).forEach((key) => {
                if (this.menuItem.items[key].selected) {
                    containsSelectedItem = true;
                }
            });
            return containsSelectedItem;
        },
        contentClass() {
            const className = [
                'nebula-accordion__content',
                {
                    'nebula-accordion__content--hidden': !this.isExpanded,
                },
            ];

            return className;
        },
        formattedId() {
            return randomStringId(this.$props.id);
        },
        hasSubMenuItems() {
            return this.menuItem && this.menuItem.items;
        },
        modeValid() {
            const supportedModes = ['outline', 'flat', 'ruled'];
            const selectedMode = this.$props.mode;

            if (supportedModes.includes(selectedMode)) {
                return selectedMode;
            }

            return 'outline';
        },
        modeClass() {
            return `nebula-accordion--${this.modeValid}`;
        },
        showContent() {
            if (this.menuItem && !this.menuItem.items) {
                return false;
            }
            return true;
        },
    },
    methods: {
        collapse() {
            this.toggle(false);
        },

        expand() {
            this.toggle(true);
        },

        getContentHeight() {
            return Math.ceil(this.$refs.contentContainer.getBoundingClientRect().height);
        },

        handleExpand() {
            if (!this.$refs.contentContainer) {
                // When there is no content in the accordion
                // i.e. the `contentContainer` does NOT exist,
                // the code for `getContentHeight()` fails.
                //
                // Since there is nothing to expand when there's
                // no content, we silently return from here.
                //
                // A prime example of this scenario is the
                // NebulaAccordion sink page, where the first few
                // examples do NOT have any content.
                //
                return;
            }

            const { isExpanded } = this;
            const expandedHeight = `${this.getContentHeight()}px`;

            if (isExpanded) {
                // Open accordion
                this.contentElAnimation = this.$refs.contentEl.animate({
                    height: [0, expandedHeight],
                }, {
                    easing: 'ease-in-out',
                    duration: 250,
                    iterations: 1,
                });
            } else {
                // Close accordion
                this.contentElAnimation = this.$refs.contentEl.animate({
                    height: [expandedHeight, 0],
                }, {
                    easing: 'ease-in-out',
                    duration: 250,
                    iterations: 1,
                });
            }

            this.contentElAnimation.onfinish = () => {
                // After the animation is finished, update the element's
                // style so the new height sticks
                if (isExpanded) {
                    this.expandHeight = 'auto';
                } else {
                    this.expandHeight = 0;
                }
            };
        },

        handleKeydown(e) {
            const handledKeys = ['ArrowUp', 'ArrowDown', 'End', 'Home'];
            const keyCode = e.code;

            if (!handledKeys.includes(keyCode)) {
                // bail if not a key we're handling
                return;
            }

            e.preventDefault();
            const directChildren = this.$el.parentNode.children;
            const filtered = Array.from(directChildren).filter((each) => each.matches('.nebula-accordion'));
            const btnSelector = 'button.nebula-accordion__button';

            if (keyCode === 'ArrowUp' || keyCode === 'ArrowDown') {
                filtered.forEach((each, idx) => {
                    each.setAttribute('data-index', idx);
                });
                const index = Number(this.$el.getAttribute('data-index') || '0');

                if (keyCode === 'ArrowUp') {
                    if (index === 0) {
                        filtered[filtered.length - 1].querySelector(btnSelector).focus();
                    } else {
                        filtered[index - 1].querySelector(btnSelector).focus();
                    }
                }
                if (keyCode === 'ArrowDown') {
                    if (index + 1 < filtered.length) {
                        filtered[index + 1].querySelector(btnSelector).focus();
                    } else {
                        filtered[0].querySelector(btnSelector).focus();
                    }
                }
            }

            if (keyCode === 'Home') {
                filtered[0].querySelector(btnSelector).focus();
            }
            if (keyCode === 'End') {
                filtered[filtered.length - 1].querySelector(btnSelector).focus();
            }
        },

        toggle(_expand = null) {
            let expand = null;

            if (_expand === null) {
                // A specific state was NOT provided.
                // Toggle the expanded state.
                expand = !this.isExpanded;
            } else {
                // A specific state WAS provided.
                // Convert to a boolean before processing
                // further.
                expand = !!_expand;
            }

            if (expand === this.isExpanded) {
                // There is no change in state.
                // DO NOT waste time processing this.
                return;
            }

            // Once we reach here, the state is set
            // to change.
            this.isExpanded = expand;

            /*--
                Fire off an event when an accordion opens or closes with the
                component as a parameter.
            --*/
            this.$emit('accordion-action', this);

            // Trip the dominoes to make the component
            // handle the new expand/collapse state.
            this.handleExpand();
        },
    },
};
</script>

<style lang="stylus">

:root {
    --nebula-accordion-border-radius: $nebula-border-radius-button-default;
    --nebula-accordion-button-background-color: $nebula-color-neutral-100;
    --nebula-accordion-button-line-height: 1.4;
    --nebula-accordion-button-text-color: $nebula-color-platform-interface-1000;
    --nebula-accordion-caret-color: $nebula-color-platform-interface-800;
    --nebula-accordion-caret-transition: transform $nebula-transition-fade-in;
    --nebula-accordion-font-size: inherit;
    --nebula-accordion-header-justify-content: space-between;
    --nebula-accordion-header-width: auto;
    --nebula-accordion-icon-color: $nebula-color-platform-interactive-900;
    --nebula-accordion-line-height: inherit;
    --nebula-accordion-padding: $nebula-space-2x;
    --nebula-accordion-preview-text-color: $nebula-color-platform-neutral-800;
    --nebula-accordion-transition: all $nebula-transition-fade-in;
    --nebula-accordion-expanded-background-color: $nebula-color-platform-interactive-100;
    --nebula-accordion-expanded-color: $nebula-color-platform-interactive-900;
    --nebula-accordion-non-outlined-background-color: $nebula-color-platform-interactive-100;
    --nebula-accordion-outline-color: $nebula-color-platform-interface-400;
    --nebula-accordion-outline-expanded-color: $nebula-color-platform-interface-400;
    --nebula-accordion-ruled-color: $nebula-color-platform-interactive-850;
    --nebula-accordion-max-width: auto;
}

rounded-modes() {
    border-radius: var(--nebula-accordion-border-radius);

    .nebula-accordion__button {
        border-radius: var(--nebula-accordion-border-radius);
        transition: var(--nebula-accordion-transition);

        &--expanded {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .nebula-accordion__content {
        border-bottom-left-radius: var(--nebula-accordion-border-radius);
        border-bottom-right-radius: var(--nebula-accordion-border-radius);
    }
}

non-outlined() {
    .nebula-accordion__content {
        background: var(--nebula-accordion-non-outlined-background-color);

        &-container {
            padding-block-start: 0;
        }
    }
}

accordion-focus() {
    .nebula-accordion__button {
        &:hover,
        &:focus-visible,
        &--expanded {
            background: var(--nebula-accordion-expanded-background-color);
            color: var(--nebula-accordion-expanded-color);

            .nebula-accordion-caret {
                fill: var(--nebula-accordion-expanded-color);
                transition: var(--nebula-accordion-caret-transition);
            }
        }

        &--expanded {
            .nebula-accordion-caret {
                transform: rotate(180deg);
            }
        }
    }
}

.nebula-accordion {
    margin-bottom: $nebula-space-2x;
    max-width: var(--nebula-accordion-max-width);

    &__button {
        nebula-text-body-2();
        nebula-text-semibold();

        appearance: unset;
        background: var(--nebula-accordion-button-background-color);
        color: var(--nebula-accordion-button-text-color);
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        line-height: var(--nebula-accordion-button-line-height);
        font-size: var(--nebula-accordion-font-size);
        padding: var(--nebula-accordion-padding);
        transition: var(--nebula-accordion-transition);
        width: 100%;
    }

    &__header {
        display: flex;
        gap: $nebula-space-1x;
        justify-content: var(--nebula-accordion-header-justify-content);
        width: var(--nebula-accordion-header-width);
    }

    &__content {
        overflow: hidden;
        transition: var(--nebula-accordion-transition);

        &-container {
            line-height: var(--nebula-accordion-line-height);
            font-size: var(--nebula-accordion-font-size);
            opacity: 1;
            overflow: hidden;
            padding: var(--nebula-accordion-padding);
        }

        &--hidden {
            border: none;
            opacity: 0;
            overflow: hidden;
            padding: 0;
            visibility: hidden;
        }
    }

    &-icon {
        fill: var(--nebula-accordion-icon-color);
        margin-inline-end: $nebula-space-1x;
        flex-shrink: 0;
    }

    &__title {
        display: flex;
        align-items: center;
        text-align: left;
    }

    &__summary {
        align-items: center;
        display: flex;
        flex-basis: 100%;
        justify-content: space-between;
    }

    &__preview {
        nebula-text-body-2();

        color: var(--nebula-accordion-preview-text-color);
        flex-basis: 100%;
        line-height: var(--nebula-accordion-line-height);
        font-size: var(--nebula-accordion-font-size);
        margin-block-start: $nebula-space-1x;
        text-align: left;

        & > * {
            margin: 0;
        }
    }

    &-caret {
        fill: var(--nebula-accordion-caret-color);
        flex-shrink: 0;
        margin-inline-start: $nebula-space-1x;
    }

    &--outline {
        accordion-focus();
        rounded-modes();

        .nebula-accordion__button {
            border-color: var(--nebula-accordion-outline-color);
            border-style: solid;
            border-width: 1px;

            &:hover,
            &:focus-visible,
            &--expanded {
                border-color: var(--nebula-accordion-outline-expanded-color);
            }
        }

        .nebula-accordion__content {
            border-color: var(--nebula-accordion-outline-color);
            border-style: solid;
            border-width: 1px;
            border-top: none;
        }
    }

    &--flat {
        accordion-focus();
        non-outlined();
        rounded-modes();

        .nebula-accordion__button {
            border: none;
        }
    }

    &--ruled {
        accordion-focus();
        non-outlined();

        .nebula-accordion__button {
            border: none;
            border-inline-start-color: var(--nebula-accordion-ruled-color);
            border-inline-start-style: solid;
            border-inline-start-width: 2px;
        }
        .nebula-accordion__content {
            border-inline-start-color: var(--nebula-accordion-ruled-color);
            border-inline-start-style: solid;
            border-inline-start-width: 2px;
        }
    }
}

</style>
