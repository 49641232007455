<template>
    <NebulaTitleTooltip
        v-if="tooltipProps"
        v-bind="tooltipProps"
    >
        <component
            :is="buttonElement"
            :class="buttonClassName"
            :aria-disabled="(isDisabled || disabledLink) ? 'true' : null"
            :aria-controls="ariaControlsElementId"
            :aria-expanded="isAriaExpanded"
            :aria-label="ariaLabel ? ariaLabel : null"
            :target="target ? target : null"
            :type="getButtonType"
            :role="disabledLink ? 'link' : null"
            :tabindex="disabledLink ? 0 : null"
            v-bind="{
                ...parsedPath,
                ...trackingData,
            }"
            :style="cssProps"
            @click="$emit('click', $event)"
        >
            <NebulaIcon
                :symbolId="icon"
                class="nebula-icon-button__icon"
            />
        </component>
    </NebulaTitleTooltip>
    <component
        v-else
        :is="buttonElement"
        :class="buttonClassName"
        :aria-disabled="(isDisabled || disabledLink) ? 'true' : null"
        :aria-label="ariaLabel ? ariaLabel : null"
        :target="target ? target : null"
        :type="getButtonType"
        :role="disabledLink ? 'link' : null"
        :tabindex="disabledLink ? 0 : null"
        v-bind="{
            ...parsedPath,
            ...$attrs,
            ...trackingData,
        }"
        :style="cssProps"
        @click="$emit('click', $event)"
    >
        <NebulaIcon
            :symbolId="icon"
            class="nebula-icon-button__icon"
        />
    </component>
</template>

<script>
import NebulaIcon from '@/components/Icon/NebulaIcon.vue';
import NebulaTitleTooltip from '@/components/Tooltip/NebulaTitleTooltip.vue';

import themingUtils from '@/utils/theming/themingUtils';

export default {
    name: 'NebulaIconButton',
    components: {
        NebulaIcon,
        NebulaTitleTooltip,
    },
    mixins: [themingUtils],
    props: {
        ariaControlsElementId: {
            type: String,
            default: null,
        },
        ariaLabel: {
            type: String,
            default: null,
        },
        disabledLink: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: null,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isAriaExpanded: {
            type: String,
        },
        isStatic: {
            type: Boolean,
            default: false,
        },
        isSubmit: {
            type: Boolean,
            default: false,
        },
        link: {
            type: [String, Object],
            default: '',
        },
        //
        // pushStateLink has been deprecated.
        // Prefer use of usePushState prop instead.
        //
        pushStateLink: {
            type: Boolean,
            default: false,
        },
        routerLink: {
            type: Boolean,
            default: false,
        },
        shape: {
            type: String,
            default: 'square',
            enum: ['square', 'pill'], // for docs site
        },
        size: {
            type: String,
            default: 'm',
            enum: ['s', 'm', 'l'],
        },
        target: {
            type: [String, Boolean],
            default: null,
            enum: ['_blank', '_self', '_parent', '_top'], // for docs site
        },
        tooltipProps: {
            type: Object,
        },
        trackingData: {
            type: Object,
            default: () => ({}),
        },
        type: {
            type: String,
            default: 'fill',
            enum: ['fill', 'ghost', 'flat'], // for docs site
        },
        usePushState: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['click'],
    computed: {
        buttonClassName() {
            const className = [
                'nebula-icon-button',
                `nebula-icon-button--${this.size}`,
                `nebula-icon-button--${this.shape}`,
                `nebula-icon-button--${this.type}`,
            ];

            if (this.pushStateLink || this.usePushState) {
                className.push('do-push-state');
            }
            //
            //  TODO: Once pushStateLink is removed uncomment this in favor of the above condition.
            //
            // if (this.usePushState) {
            //     className.push('do-push-state');
            // }

            return className;
        },
        buttonElement() {
            return this.getButtonElement();
        },
        getButtonType() {
            let type = 'button';

            if (this.isSubmit) {
                type = 'submit';
            }

            if (this.routerLink || this.link || this.disabledLink || this.isStatic) {
                type = null;
            }

            return type;
        },
        parsedPath() {
            const { link } = this;
            const buttonEl = this.getButtonElement();
            let parsedLink = {};

            switch (buttonEl) {
            case 'a':
                parsedLink = {
                    href: link,
                };
                break;
            case 'routerLink':
                parsedLink = {
                    to: link,
                };
                break;
            default:
                break;
            }

            return parsedLink;
        },
    },
    methods: {
        getButtonElement() {
            let buttonElement = 'button';

            if (this.routerLink) {
                buttonElement = 'routerLink';
            } else if (this.isStatic) {
                buttonElement = 'div';
            } else if (this.link || this.disabledLink) {
                buttonElement = 'a';
            }

            return buttonElement;
        },
    },
};
</script>

<style lang="stylus">
:root {
    --nebula-icon-button-background: $nebula-color-platform-interactive-850;
    --nebula-icon-button-background-hover: $nebula-color-platform-interactive-900;
    --nebula-icon-button-background-active: $nebula-color-platform-interactive-1000;
    --nebula-icon-button-background-disabled: rgba($nebula-color-platform-interactive-850, .3);
    --nebula-icon-button-border: none;
    --nebula-icon-button-border-color: $nebula-color-platform-interactive-850;
    --nebula-icon-button-border-color-hover: $nebula-color-platform-interactive-900;
    --nebula-icon-button-border-color-active: $nebula-color-platform-interactive-1000;
    --nebula-icon-button-border-color-disabled: rgba($nebula-color-platform-interactive-850, .3);
    --nebula-icon-button-border-radius: $nebula-border-radius-button-default;
    --nebula-icon-button-padding: 11px;
    --nebula-icon-button-fill: $nebula-color-platform-white;
    --nebula-icon-button-fill-hover: $nebula-color-platform-white;
    --nebula-icon-button-fill-active: $nebula-color-platform-white;
    --nebula-icon-button-fill-disabled: $nebula-color-platform-white;
    --nebula-icon-button-transition: all $nebula-transition-default;
}

.nebula-icon-button {
    align-items: center;
    background: var(--nebula-icon-button-background);
    border: var(--nebula-icon-button-border);
    border-color: var(--nebula-icon-button-border-color);
    border-radius: var(--nebula-icon-button-border-radius);
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    padding: var(--nebula-icon-button-padding);
    transition: var(--nebula-icon-button-transition);
    width: max-content;

    .nebula-icon-button__icon {
        fill: var(--nebula-icon-button-fill);
        height: 18px;
        transition: var(--nebula-icon-button-transition);
        vertical-align: middle;
        width: 18px;
    }

    &.nebula-icon-button--ghost {
        --nebula-icon-button-padding: 10px; // Reduce padding by 1 to account for border

    }

    &:hover {
        cursor: pointer;
    }

    &:hover,
    &:focus-visible {
        background: var(--nebula-icon-button-background-hover);
        border-color: var(--nebula-icon-button-border-color-hover);
        color: var(--nebula-icon-button-fill-hover);

        .nebula-icon-button__icon {
            fill: var(--nebula-icon-button-fill-hover);
        }
    }

    &:active {
        background: var(--nebula-icon-button-background-active);
        border-color: var(--nebula-icon-button-border-color-active);
        color: var(--nebula-icon-button-fill-active);

        .nebula-icon-button__icon {
            fill: var(--nebula-icon-button-fill-active);
        }
    }

    &:disabled,
    &[aria-disabled='true'] {
        background: var(--nebula-icon-button-background-disabled);
        border-color: var(--nebula-icon-button-border-color-disabled);
        color: var(--nebula-icon-button-fill-disabled);
        pointer-events: none;

        .nebula-icon-button__icon {
            fill: var(--nebula-icon-button-fill-disabled);
        }
    }
}

.nebula-icon-button--xs {
    --nebula-icon-button-padding: $nebula-space-half;

    .nebula-icon-button__icon {
        nebula-icon-s();
    }

    &.nebula-icon-button--ghost {
        --nebula-icon-button-padding: 3px; // Reduce padding by 1 to account for border
    }
}

.nebula-icon-button--s {
    --nebula-icon-button-padding: $nebula-space-1x;

    .nebula-icon-button__icon {
        nebula-icon-s();
    }

    &.nebula-icon-button--ghost {
        --nebula-icon-button-padding: 7px; // Reduce padding by 1 to account for border
    }
}

.nebula-icon-button--l {
    --nebula-icon-button-padding: 15px;

    .nebula-icon-button__icon {
        height: 18px;
        width: 18px;
    }

    &.nebula-icon-button--ghost {
        --nebula-icon-button-padding: 14px; // Reduce padding by 1 to account for border
    }
}

.nebula-icon-button--pill {
    --nebula-icon-button-border-radius: $nebula-border-radius-button-pill;
}

.nebula-icon-button--ghost {
    --nebula-icon-button-background: transparent;
    --nebula-icon-button-background-hover: $nebula-color-platform-interactive-900;
    --nebula-icon-button-background-active: $nebula-color-platform-interactive-1000;
    --nebula-icon-button-background-disabled: transparent;
    --nebula-icon-button-border: 1px solid;
    --nebula-icon-button-fill: $nebula-color-platform-interactive-850;
    --nebula-icon-button-fill-hover: $nebula-color-platform-white;
    --nebula-icon-button-fill-active: $nebula-color-platform-white;
    --nebula-icon-button-fill-disabled: rgba($nebula-color-platform-interactive-850, .3);
}

.nebula-icon-button--flat {
    --nebula-icon-button-background: transparent;
    --nebula-icon-button-background-hover: $nebula-color-platform-interactive-200;
    --nebula-icon-button-background-active: $nebula-color-platform-interactive-400;
    --nebula-icon-button-background-disabled: transparent;
    --nebula-icon-button-fill: $nebula-color-platform-interactive-850;
    --nebula-icon-button-fill-hover: $nebula-color-platform-interactive-850;
    --nebula-icon-button-fill-active: $nebula-color-platform-interactive-900;
    --nebula-icon-button-fill-disabled: rgba($nebula-color-platform-interactive-850, .3);
}
</style>
