<template>
    <label
        class="nebula-label"
        :for="inputId"
    >
        <span v-if="responsiveLabelText">
            <span class="nebula-label__responsive-text">
                {{ responsiveLabelText }}
            </span>
            <span class="nebula-label__desktop-text">
                {{ labelText }}
            </span>
        </span>
        <template v-else>
            {{ labelText }}
        </template>
        <span
            v-if="isRequired"
            :aria-hidden="isAriaHidden"
            class="nebula-label__required"
            :class="requiredLabelText ? 'nebula-label__required--label-text' : null"
            :id="requiredLabelId"
        >
            {{ requireText }}
        </span>
    </label>
</template>

<script>
export default {
    name: 'NebulaLabel',
    props: {
        inputId: {
            type: [String, Number],
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        hideRequiredTextFromSR: {
            type: Boolean,
            default: false,
        },
        labelText: {
            type: String,
            default: null,
        },
        requiredLabelId: {
            type: String,
            default: null,
        },
        responsiveLabelText: {
            type: String,
            default: null,
        },
        requiredLabelText: {
            type: String,
            default: null,
        },
    },

    computed: {
        isAriaHidden() {
            let ariaHidden = null;

            if ((this.isRequired && !this.requiredLabelText) || this.hideRequiredTextFromSR) {
                ariaHidden = 'true';
            }

            return ariaHidden;
        },
        requireText() {
            return this.requiredLabelText ? `(${this.requiredLabelText})` : '*';
        },
    },
};
</script>

<style lang="stylus">

.nebula-label {
    display: block;
    font-size: $nebula-font-size-body-2;
    font-weight: 500;
    margin-bottom: $nebula-space-1x;

    &__responsive-text {
        display: inline;
    }

    &__desktop-text {
        display: none;
    }

    &__required {
        color: $nebula-color-error;
        margin-inline-start: $nebula-space-quarter;

        &--label-text {
            margin-inline-start: $nebula-space-half;
        }
    }
}

@media (min-width: $nebula-breakpoints-tablet-portrait) {
    .nebula-label {
        &__responsive-text {
            display: none;
        }

        &__desktop-text {
            display: inline;
        }
    }
}

</style>
