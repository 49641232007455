<template>
    <PageWrapper class="nebula-home">
        <h1>Nebula Home</h1>
        <p>
            Welcome to the Nebula Doc Site!
            <span class="nebula-home__text--desktop">Enter a component name to do a Quick Search.</span>
            <span class="nebula-home__text--responsive">Open the Nav menu to browse components.</span>
        </p>
        <NebulaImage
            class="nebula-home__image"
            covered
            alt="Collage of nebula components"
            src="images/component-collage.webp"
        />
    </PageWrapper>
</template>

<script>
import PageWrapper from '@/doc-components/PageWrapper.vue';
import NebulaImage from '@/components/Image/NebulaImage.vue';

export default {
    name: 'HomePage',
    components: {
        PageWrapper,
        NebulaImage,
    },
};
</script>

<style lang="stylus">
    .nebula-home {
        &__image {
            max-width: 1440px;
            width: 100%;
        }

        .nebula-home__text {
            &--responsive {
                @media (min-width: 600px) {
                    display: none;
                }
            }

            &--desktop {
                display: none;

                @media (min-width: 600px) {
                    display: inline;
                }
            }
        }
    }
</style>
