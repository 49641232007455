<template>
    <div class="docs-layout">
        <a href="#docs-main" class="skip-navigation-link">Skip to Main Content</a>
        <nav class="docs-layout__sink-nav">
            <div class="docs-layout__sink-nav-primary">
                <div class="docs-layout__sink-nav-back-buttons">
                    <NebulaButton
                        class="docs-layout__sink-nav-home"
                        aria-label="Back"
                        iconRight="home"
                        type="flat"
                        link="/"
                        routerLink
                    />
                </div>
                <p class="docs-layout__sink-nav-title">Nebula Design System</p>
            </div>
            <div class="docs-layout__sink-nav-secondary">
                <NebulaPopover
                    aria-label="Layout Options Menu"
                    id="docs-layout-options-popover"
                >
                    <template v-slot:content>
                        <RtlViewSwitch />
                        <NebulaFieldGroup
                            class="sidebar-toggle-switch"
                            inputId="nebula-docs-sidebar-toggle"
                            labelText="Toggle Sidebar Visibility"
                            labelPosition="inline"
                            responsiveLabelText="Sidebar"
                        >
                            <template v-slot:formElement>
                                <NebulaSwitch isCompressed>
                                    <template v-slot:checkbox>
                                        <input
                                            class="nebula-switch__input"
                                            id="nebula-docs-sidebar-toggle"
                                            @input="toggleSwitch"
                                            ref="sidebarToggle"
                                            type="checkbox"
                                        >
                                    </template>
                                </NebulaSwitch>
                            </template>
                        </NebulaFieldGroup>
                    </template>
                    <template v-slot:trigger="{ popoverOpen }">
                        <NebulaButton
                            :aria-expanded="popoverOpen && 'true' || 'false'"
                            aria-haspopup="true"
                            aria-label="Open layout options menu"
                            icon-right="ellipses"
                            id="nebula-popover-trigger__docs-layout-options-popover"
                            size="s"
                            :text="getLayoutButtonText"
                        />
                    </template>
                </NebulaPopover>
            </div>
        </nav>
        <div class="docs-layout__inner">
            <NebulaSidebar
                id="nebula-docsite-sidebar"
                element="nav"
                responsiveButtonTextClose="Close Nav"
                responsiveButtonTextOpen="Open Nav"
                useAccordionGroups
                useResponsiveCollapseOnload
            >
                <template v-slot:sidebar-top>
                    <div class="nebula-component-search">
                        <NebulaInput
                            aria-label="search for component"
                            id="nebula-component-search"
                            placeholder="Quick Search"
                            @input="filterComponents"
                            ref="quickSearchRef"
                        />
                        <NebulaButton
                            id="nebula-component-search__clear-button"
                            @click="resetFilter"
                            type="flat"
                            icon-left="x"
                            aria-label="clear search"
                        />
                    </div>
                </template>
                <template v-slot:sidebar-content>
                    <div
                        class="nebula-sidebar__section"
                        :key="`sidebar-key--${sidebarKey}`"
                    >
                        <NebulaAccordion
                            v-for="(section, index) in sidebarData"
                            :key="index"
                            class="nebula-sidebar__accordion"
                            :class="section.hidden && section.hidden === true ? 'nebula-sidebar__accordion--hidden' : null"
                            :isExpandedOnLoad="section.expanded"
                            mode="flat"
                            :text="$t(section.section_title)"
                        >
                            <ul class="section__list">
                                <NebulaSidebarItem
                                    v-for="(item, i) in section.items"
                                    :key="i"
                                    :item="item"
                                />
                            </ul>
                        </NebulaAccordion>
                    </div>
                </template>
                <template v-slot:sidebar-bottom>
                    <div class="nebula-component-search__no-results">
                        <p role="alert">Sorry, no match found.</p>
                    </div>
                </template>
            </NebulaSideBar>
            <main class="docs-layout__main" id="docs-main">
                <RouterView />
            </main>
        </div>
    </div>
</template>

<script>
import NebulaAccordion from '@/components/Accordion/NebulaAccordion.vue';
import NebulaButton from '@/components/Button/NebulaButton.vue';
import NebulaInput from '@/components/FormElements/NebulaInput.vue';
import NebulaSidebar from '@/components/Sidebar/NebulaSidebar.vue';
import RtlViewSwitch from '@/doc-components/RtlViewSwitch.vue';
import NebulaFieldGroup from '@/components/FormElements/NebulaFieldGroup.vue';
import NebulaSidebarItem from '@/components/Sidebar/NebulaSidebarItem.vue';
import NebulaSwitch from '@/components/FormElements/NebulaSwitch.vue';
import NebulaPopover from '@/components/Popover/NebulaPopover.vue';

/* eslint-disable no-param-reassign */
export default {
    name: 'NebulaApp',
    components: {
        NebulaAccordion,
        NebulaButton,
        NebulaInput,
        NebulaSidebar,
        RtlViewSwitch,
        NebulaFieldGroup,
        NebulaSidebarItem,
        NebulaSwitch,
        NebulaPopover,
    },
    data() {
        return {
            sidebarKey: 0,
            sidebarData: [
                {
                    section_title: 'Visual Language',
                    items: [
                        {
                            name: 'Color',
                            url: '/color-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Grid',
                            url: '/grid-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Icon',
                            url: '/icon-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Tokens',
                            url: '/tokens',
                            routerLink: true,
                        },
                        {
                            name: 'Typography',
                            url: '/type-sink',
                            routerLink: true,
                        },
                    ],
                },
                {
                    section_title: 'Components',
                    items: [
                        {
                            name: 'Accordion',
                            url: '/accordion',
                            routerLink: true,
                        },
                        {
                            name: 'Back to Top',
                            url: '/back-to-top-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Badge',
                            url: '/badges-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Banner',
                            url: '/banner-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Block Message',
                            url: '/block-message-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Button',
                            url: '/buttons-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Button Group',
                            url: '/button-group-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Call To Action',
                            url: '/call-to-action-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Callout',
                            url: '/callout-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Card',
                            url: '/cards-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Card Carousel',
                            url: '/card-carousel-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Carousel',
                            url: '/carousel-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Combobox',
                            url: '/combobox-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Copy Button',
                            url: '/copy-button-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Datepicker',
                            url: '/datepicker-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Dialog',
                            url: '/dialog-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Dropdown',
                            url: '/dropdown-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Feature Row',
                            url: '/feature-row-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Floating Action Button',
                            url: '/fab-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Fullscreen',
                            url: '/fullscreen-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Hero',
                            url: '/hero-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Image',
                            url: '/image-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Input with Buttons',
                            url: '/input-with-buttons-sink',
                            routerLink: true,
                        },
                        {
                            name: 'List Group',
                            url: '/list-group-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Modal',
                            url: '/modal-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Page Shell',
                            url: '/page-shell',
                            routerLink: true,
                        },
                        {
                            name: 'Picture',
                            url: '/picture-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Popover',
                            url: '/popover-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Product Block',
                            url: '/product-block-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Row',
                            url: 'row-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Switch',
                            url: '/switch-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Sticker',
                            url: '/sticker-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Table',
                            url: '/table-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Tabs',
                            url: '/tabs-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Tags',
                            url: '/tags-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Testimonial',
                            url: '/testimonial-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Title Block',
                            url: '/titleblock-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Thumbnail',
                            url: '/thumbnail-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Toast Message',
                            url: '/toast-message-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Tooltip',
                            url: '/tooltip-sink',
                            routerLink: true,
                        },
                    ],
                },
                {
                    section_title: 'Navigation',
                    items: [
                        {
                            name: 'Action Bar',
                            url: '/action-bar-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Breadcrumbs',
                            url: '/breadcrumbs-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Global Nav',
                            url: '/global-nav',
                            routerLink: true,
                        },
                        {
                            name: 'Footer',
                            url: '/footer',
                            routerLink: true,
                        },
                        {
                            name: 'Menu Bar',
                            url: '/menu-bar-sink',
                            routerLink: true,
                        },
                        {
                            name: 'MultiLevelNav',
                            url: '/multi-level-nav',
                            routerLink: true,
                        },
                        {
                            name: 'Pagination',
                            url: '/pagination-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Product Bar',
                            url: '/product-bar-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Quicklinks',
                            url: '/quicklinks-sink',
                            routerLink: true,
                        },
                    ],
                },
                {
                    section_title: 'Panels',
                    items: [
                        {
                            name: 'Favorites',
                            url: '/favorites-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Icon Sidebar',
                            url: '/icon-sidebar-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Panel',
                            url: 'panel-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Profile Panel',
                            url: '/profile-panel-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Sidebar',
                            url: '/sidebar-sink',
                            routerLink: true,
                        },
                    ],
                },
                {
                    section_title: 'Form Elements',
                    items: [
                        {
                            name: 'Checkbox',
                            url: '/checkbox-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Input',
                            url: '/input-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Radio',
                            url: 'radio-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Required',
                            url: '/required-form-elements',
                            routerLink: true,
                        },
                        {
                            name: 'Select',
                            url: '/select-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Textarea',
                            url: '/textarea-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Vue Select',
                            url: '/vue-select-sink',
                            routerLink: true,
                        },
                    ],
                },
                {
                    section_title: 'Doc-Site Components',
                    items: [
                        {
                            name: 'Code Pair',
                            url: '/code-pair-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Do/Dont',
                            url: '/do-dont-sink',
                            routerLink: true,
                        },
                        {
                            name: 'Image Example',
                            url: '/image-example',
                            routerLink: true,
                        },
                    ],
                },
                {
                    section_title: 'Accessibility',
                    items: [
                        {
                            name: 'Aria-disabled',
                            url: '/disabled-demo',
                            routerLink: true,
                        },
                    ],
                },
            ],
            quickSearchTerm: null,
            windowSize: null,
        };
    },
    computed: {
        app() {
            return document.getElementById('app');
        },
        clearFilterButton() {
            return document.getElementById('nebula-component-search__clear-button');
        },
        getSidebarEl() {
            return document.getElementsByClassName('nebula-sidebar')[0];
        },
        noResultMessage() {
            return document.getElementsByClassName('nebula-component-search__no-results')[0];
        },
        searchFilter() {
            return document.getElementById('nebula-component-search');
        },
        pageName() {
            return this.$route.name;
        },
        getLayoutButtonText() {
            let text = '';

            if (this.windowSize > 500) {
                text = 'Layout Options';
            }

            return text;
        },
    },
    methods: {
        filterComponents() {
            this.quickSearchTerm = this.$refs.quickSearchRef.$el.value;

            if (this.quickSearchTerm.length > 0 && this.quickSearchTerm !== '') {
                this.clearFilterButton.style.display = 'block';
            } else {
                this.clearFilterButton.style.display = 'none';
            }

            let hasValidResult = false;

            const term = this.quickSearchTerm.toLowerCase();
            const sidebarSections = document.getElementById('nebula-docsite-sidebar').querySelectorAll('.section__list');

            sidebarSections.forEach((section, sectionIndex) => {
                const sidebarItems = section.querySelectorAll('.section__list-item');
                let sectionContainsSearchTerm = false;

                sidebarItems.forEach((item, itemIndex) => {
                    const dataName = item.dataset.name.toLowerCase();

                    if (term === '') {
                        Object.assign(this.sidebarData[sectionIndex], { expanded: false, hidden: false });
                        Object.assign(this.sidebarData[sectionIndex].items[itemIndex], { hidden: false });
                        this.setActiveSidebarItem(this.$route.name);
                    } else {
                        if (dataName.includes(term)) {
                            hasValidResult = true;
                            sectionContainsSearchTerm = true;
                        }

                        if (sectionContainsSearchTerm && term !== '') {
                            Object.assign(this.sidebarData[sectionIndex], { expanded: true, hidden: false });
                        } else {
                            Object.assign(this.sidebarData[sectionIndex], { expanded: false, hidden: true });
                        }

                        if (dataName.includes(term)) {
                            Object.assign(this.sidebarData[sectionIndex].items[itemIndex], { hidden: false });
                        } else {
                            Object.assign(this.sidebarData[sectionIndex].items[itemIndex], { hidden: true });
                        }
                    }

                    this.forceRerender();
                });
            });

            if (hasValidResult) {
                this.noResultMessage.style.display = 'none';
            } else if (term === '') {
                this.noResultMessage.style.display = 'none';
            } else {
                this.noResultMessage.style.display = 'block';
            }
        },
        forceRerender() {
            this.sidebarKey += 1;
        },
        getValidHeaders() {
            const validHeaders = [];

            const allHeaders = document.getElementById('docs-main').querySelectorAll('h2, h3');

            if (allHeaders) {
                allHeaders.forEach((header) => {
                    const { classList } = header;

                    // setHeaderIds adds an ID to all h2s or h3s without a class
                    // or with the nebula-doc-row__header class
                    // Do not add classless h2s or h3s to examples or
                    // they will get dynamic IDs and will appear in HeaderNav
                    // Add nebula-header-nav--ignore if necessary

                    if (!classList.length > 0) {
                        validHeaders.push(header);
                    }

                    classList.forEach((classname) => {
                        if (classname.includes('nebula-doc-row__header')) {
                            validHeaders.push(header);
                        }
                    });
                });
            }

            return validHeaders;
        },
        resetFilter() {
            this.$refs.quickSearchRef.$el.value = '';
            this.clearFilterButton.style.display = 'none';
            this.searchFilter.focus();

            const sidebarSections = document.getElementById('nebula-docsite-sidebar').querySelectorAll('.section__list');

            sidebarSections.forEach((section, sectionIndex) => {
                const sidebarItems = section.querySelectorAll('.section__list-item');

                Object.assign(this.sidebarData[sectionIndex], { expanded: false, hidden: false });
                sidebarItems.forEach((item, itemIndex) => {
                    Object.assign(this.sidebarData[sectionIndex].items[itemIndex], { hidden: false });
                });

                this.setActiveSidebarItem(this.$route.name);

                this.forceRerender();
            });
        },
        setHeaderIds() {
            this.$nextTick(() => {
                const headers = this.getValidHeaders();
                headers.forEach((header, i) => {
                    const headerText = header.innerText;
                    header.id = `${headerText.replace(/[^\w\s-]+/g, '').replace(/\s/g, '-').toLowerCase()}-${i}`;
                });
            });
        },
        setActiveSidebarItem(routeName) {
            const sidebarSections = document.getElementById('nebula-docsite-sidebar').querySelectorAll('.section__list');

            sidebarSections.forEach((section, sectionIndex) => {
                const sidebarItems = section.querySelectorAll('.section__list-item');
                let sectionContainsSearchTerm = false;

                sidebarItems.forEach((item, itemIndex) => {
                    if (item.dataset.name.toLowerCase() === routeName.toLowerCase()) {
                        Object.assign(this.sidebarData[sectionIndex].items[itemIndex], { active: true });
                        sectionContainsSearchTerm = true;
                    } else {
                        Object.assign(this.sidebarData[sectionIndex].items[itemIndex], { active: false });
                    }
                });

                if (sectionContainsSearchTerm) {
                    Object.assign(this.sidebarData[sectionIndex], { expanded: true });
                } else {
                    Object.assign(this.sidebarData[sectionIndex], { expanded: false });
                }

                this.forceRerender();
            });
        },
        setPageTitle(page) {
            document.title = `Nebula Components | ${page}Discovery Education`;
        },
        toggleSwitch() {
            if (this.hideSidebar) {
                this.getSidebarEl.style.display = 'block';
            } else {
                this.getSidebarEl.style.display = 'none';
            }

            this.hideSidebar = !this.hideSidebar;
        },
        setWindowSize() {
            this.windowSize = window.innerWidth;
            return null;
        },
    },
    watch: {
        $route(newVal) {
            this.setHeaderIds();
            this.setPageTitle(`${newVal.name} | `);
            this.setActiveSidebarItem(newVal.name);
        },
    },
    mounted() {
        this.setHeaderIds();
        this.windowSize = window.innerWidth;

        window.addEventListener('resize', this.setWindowSize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.setWindowSize);
    },
};
/* eslint-enable no-param-reassign */
</script>

<style lang="stylus">
@import './styles/index.styl';

.docs-layout {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;

    .skip-navigation-link {
        position: absolute;
        transition: none;
        z-index: -1;
    }

    &__sink-nav {
        background: $nebula-color-white;
        box-shadow: $nebula-shadow-200;
        display: flex;
        height: 56px;
        justify-content: space-between;
        padding-right: $nebula-space-2x;
        z-index: 4;

        &-primary {
            align-items: center;
            display: grid;
            gap: $nebula-space-2x;
            grid-template-columns: max-content 1fr;
        }

        &-secondary {
            align-items: center;
            display: flex;
            gap: $nebula-space-2x;
        }
    }

    &__sink-nav-back-buttons {
        display: flex;
    }

    &__sink-nav-home,
    &__sink-nav-back {
        border-inline-end: 1px solid $nebula-color-platform-interface-300;
        border-radius: 0;
        height: 56px;
        justify-content: center;
        width: 56px;
    }

    &__sink-nav-title {
        nebula-text-body-1()

        font-weight: 600;
        margin: 0;
        overflow: hidden;
        padding-inline-end: $nebula-space-2x;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__sink-nav-button {
        color: $nebula-color-platform-interactive-1000;
        padding: 0;

        &:hover,
        &:focus-visible {
            background: transparent;
            text-decoration: underline;
            text-decoration-skip-ink: auto;
        }
    }

    &__inner {
        display: flex;
        width: 100%;

        > .nebula-sidebar {
            flex-shrink: 0;
            z-index: 2; // make sure the example Sidebar collapses under the Doc Site sidebar

            .nebula-component-search {
                display: flex;
                position: relative;

                .nebula-button {
                    display: none;
                    inset-inline-end: 0;
                    padding: $nebula-space-1x;
                    position: absolute;

                    &:active,
                    &:focus-visible,
                    &:hover {
                        background-color: transparent;

                        svg {
                            fill: $nebula-color-platform-interactive-900;
                        }
                    }
                }
            }

            .nebula-component-search__no-results {
                display: none;
                width: fit-content;
            }
        }
    }

    &__main {
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 0;
    }
}

.nebula-rtl-view-switch {
    .nebula-field-group {
        margin: 0;
    }
}

.sidebar-toggle-switch {
    margin: 0;
}

</style>
