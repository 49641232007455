import axios from 'axios';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import { createApp } from 'vue';
import pluralTerms from '@/utils/constants/i18nPluralTerms.json';
import NebulaApp from './NebulaApp.vue';
import router from './router';

// Global styles for the docs site
import '@discoveryedu/nebula-core/dist/styles/nebula_core.css';
import '@discoveryedu/nebula-core/dist/styles/fonts/nebula_fonts.css';
import '@discoveryedu/nebula-core/dist/styles/nebula_tokens.css';

function initI18n() {
    return new Promise((resolve) => {
        const i18nextConfig = {
            lng: 'en',
            resources: {
                en: {
                    translation: Object.keys(pluralTerms).reduce((acc, key) => {
                        acc[`${key}_plural`] = pluralTerms[key];
                        return acc;
                    }, {}),
                },
            },
            returnEmptyString: false,
            interpolation: {
                prefixEscaped: '%\\(',
                suffixEscaped: '\\)[sd]',
            },
            nsSeparator: false,
            keySeparator: false,
        };
        const localeCookie = document.cookie.split(';')
            .find((cookie) => cookie.trim().startsWith('L10N_LANG'));

        const locale = localeCookie ? localeCookie.trim().split('=')[1] : 'en';

        if (locale !== 'en') {
            // i18next expects locales to use hyphens
            const formattedLocale = locale.replace(/_/g, '-');
            i18nextConfig.lng = formattedLocale;

            const deAppOrigin = window.location.href.includes('localhost')
                ? 'https://app.local.discoveryeducation.com'
                : window.location.origin.replace('nebula', 'app');

            axios.get(`${deAppOrigin}/static/common/components/nunjucks-config/localization/${locale.toLowerCase()}.json`)
                .then((response) => {
                    i18nextConfig.fallbackLng = false;
                    i18nextConfig.resources[formattedLocale] = {
                        translation: response.data,
                    };
                    resolve(i18nextConfig);
                });
        } else {
            resolve(i18nextConfig);
        }
    });
}

initI18n().then((i18nextConfig) => {
    i18next.init(i18nextConfig);

    const app = createApp(NebulaApp);

    app.use(router);

    // Setup for i18next
    app.use(I18NextVue, { i18next });

    app.config.globalProperties.$nebulaSVGPath = null;

    app.mount('#app');
});
