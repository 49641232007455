<template>
    <NebulaFloatingActionButton
        class="nebula-back-to-top"
        :style="cssProps"
        :tooltipText="this.tooltipText"
    >
        <NebulaButton
            iconLeft="arrow-up-circle"
            :aria-label="this.tooltipText"
            @click="this.scrollToTop"
        >
            <template v-slot:slotIconLeft>
                <NebulaIcon symbolId="arrow-up-circle" />
            </template>
        </NebulaButton>
    </NebulaFloatingActionButton>
</template>

<script>
import NebulaButton from '@/components/Button/NebulaButton.vue';
import NebulaFloatingActionButton from '@/components/Button/NebulaFloatingActionButton.vue';
import NebulaIcon from '@/components/Icon/NebulaIcon.vue';

import themingUtils from '@/utils/theming/themingUtils';

export default {
    name: 'NebulaBackToTop',
    components: {
        NebulaButton,
        NebulaFloatingActionButton,
        NebulaIcon,
    },
    computed: {
        tooltipText() {
            return this.$t('Back to Top');
        },
    },
    mixins: [themingUtils],
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style lang="stylus">
:root {
    --nebula-back-to-top-background: $nebula-color-white;
    --nebula-back-to-top-background-hover: $nebula-color-white;
    --nebula-back-to-top-background-active: $nebula-color-white;
    --nebula-back-to-top-border-radius: 50%;
    --nebula-back-to-top-hover-offset: $nebula-space-quarter;
}

.nebula-back-to-top {
    --nebula-button-background: var(--nebula-back-to-top-background);
    --nebula-button-background-hover: var(--nebula-back-to-top-background-hover);
    --nebula-button-background-active: var(--nebula-back-to-top-background-active);
    --nebula-button-border-radius: var(--nebula-back-to-top-border-radius);
    --nebula-button-padding: $nebula-space-3x;
    --nebula-button-text-color: $nebula-color-black;
    --nebula-button-text-color-hover: $nebula-color-black;
    --nebula-button-text-color-active: $nebula-color-black;
    border-radius: var(--nebula-back-to-top-border-radius);
    transition: all .3s ease-in;

    &:hover {
        transform: 'translate(0, %s)' % calc(var(--nebula-back-to-top-hover-offset)*-1);
    }

    button {
        // cannot override --nebula-button-padding assignment within a double selector
        // this applies the correct padding without setting the variable
        padding: $nebula-space-3x;
    }
}
</style>
