export default {
    props: {
        customStyles: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            // default to nebula
            // can be overridden by setting this value to a different namespace
            // use only for other brands/applications
            themePrefix: 'nebula',
        };
    },
    methods: {
        cssPropsCompute(customStyles, prefix = 'nebula', cb) {
            // if you need to do something fancy with the custom styles, just add it as
            // a function in the second argument.
            // See NebulaFeatureRow for example
            const customProps = {};

            if (customStyles) {
                // Loop through the customStyles object, and for each entry creates the css custom property and assigns it the value of the entry
                Object.entries(customStyles).forEach(
                    // eslint-disable-next-line no-return-assign, prefer-destructuring
                    (entry) => customProps[`--${prefix}-${entry[0].replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`)}`] = entry[1],
                    // The regex here is converting the camelCase object keys to hyphen-case for use as css custom properties
                );
            }
            if (cb && typeof cb === 'function') {
                cb(customProps);
            }

            return customProps;
        },
    },
    computed: {
        cssProps() {
            return this.cssPropsCompute(this.customStyles, this.themePrefix);
        },
    },
};
