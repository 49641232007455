<template>
    <component
        :is="buttonElement"
        :class="buttonClassName"
        :aria-disabled="(isDisabled || disabledLink) || null"
        :aria-label="ariaLabel ? ariaLabel : null"
        :target="validTarget"
        :type="getButtonType"
        :role="buttonRole"
        :tabindex="disabledLink ? 0 : null"
        v-bind="{
            ...parsedPath,
            ...$attrs,
        }"
        :style="cssProps"
    >
        <slot name="slotIconLeft">
            <NebulaIcon
                v-if="iconLeft"
                :symbolId="iconLeft"
                class="nebula-button__icon"
            />
        </slot>
        <span
            v-if="text"
            class="nebula-button__text"
            :aria-hidden="ariaLabel ? 'true' : null"
        >{{ text }}</span>
        <slot name="slotIconRight">
            <NebulaIcon
                v-if="iconRight"
                :symbolId="iconRight"
                class="nebula-button__icon"
            />
        </slot>
        <div v-if="hasBadgeWithText" class="nebula-button__badge-with-text">
            <slot name="badgeWithText" />
        </div>
    </component>
</template>

<script>
/*
    Make sure there is no whitespace around
    the "{{ text }}" otherwise it will end up
    rendering the whitespace in the DOM and
    affect offset-based component calculations
    like the ones used in highlighting during
    rendering.
*/

import NebulaIcon from '@/components/Icon/NebulaIcon.vue';
import themingUtils from '@/utils/theming/themingUtils';

export default {
    name: 'NebulaButton',
    components: {
        NebulaIcon,
    },
    mixins: [themingUtils],
    props: {
        ariaLabel: {
            type: String,
            default: null,
        },
        disabledLink: {
            type: Boolean,
            default: false,
        },
        iconLeft: {
            type: String,
            default: null,
        },
        iconRight: {
            type: String,
            default: null,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isStatic: {
            type: Boolean,
            default: false,
        },
        isSubmit: {
            type: Boolean,
            default: false,
        },
        link: {
            type: [String, Object],
            default: '',
        },
        //
        // pushStateLink has been deprecated.
        // Prefer use of usePushState prop instead.
        //
        pushStateLink: {
            type: Boolean,
            default: false,
        },
        role: {
            type: String,
            default: null,
        },
        routerLink: {
            type: Boolean,
            default: false,
        },
        shape: {
            type: String,
            default: 'square',
            enum: ['square', 'pill'], // for docs site
        },
        size: {
            type: String,
            default: 'm',
            enum: ['xs', 's', 'm', 'l'],
        },
        target: {
            type: [String, Boolean],
            default: null,
            enum: ['_blank', '_self', '_parent', '_top'], // for docs site
        },
        text: {
            type: [String, Number],
            default: null,
        },
        type: {
            type: String,
            default: 'fill',
            enum: ['fill', 'ghost', 'flat'], // for docs site
        },
        usePushState: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        buttonClassName() {
            const className = [
                'nebula-button',
                `nebula-button--${this.size}`,
                `nebula-button--${this.shape}`,
                `nebula-button--${this.type}`,
                {
                    'nebula-button--icon-left': this.iconLeft || this.usesIconLeftSlot,
                    'nebula-button--icon-right': this.iconRight || this.usesIconRightSlot,
                    'nebula-button--icon-only': !this.text,
                },
            ];

            if (this.pushStateLink || this.usePushState) {
                className.push('do-push-state');
            }
            //
            //  TODO: Once pushStateLink is removed uncomment this in favor of the above condition.
            //
            // if (this.usePushState) {
            //     className.push('do-push-state');
            // }

            return className;
        },
        buttonElement() {
            return this.getButtonElement();
        },

        buttonRole() {
            if (this.role) {
                return this.role;
            }

            if (this.disabledLink) {
                return 'link';
            }

            return null;
        },

        getButtonType() {
            let type = 'button';

            if (this.isSubmit) {
                type = 'submit';
            }

            if (this.routerLink || this.link || this.disabledLink || this.isStatic) {
                type = null;
            }

            return type;
        },
        validTarget() {
            if (this.isDisabled || !this.target) {
                return null;
            }
            return this.target;
        },
        hasBadgeWithText() {
            return !!this.$slots.badgeWithText;
        },
        usesIconLeftSlot() {
            return !!this.$slots.slotIconLeft;
        },
        usesIconRightSlot() {
            return !!this.$slots.slotIconRight;
        },
        parsedPath() {
            const { link } = this;
            const buttonEl = this.getButtonElement();
            let parsedLink = {};

            if (this.disabledLink) {
                return parsedLink;
            }

            switch (buttonEl) {
            case 'a':
                parsedLink = {
                    href: link,
                };
                break;
            case 'routerLink':
                parsedLink = {
                    to: link,
                };
                break;
            default:
                break;
            }

            return parsedLink;
        },
    },
    methods: {
        getButtonElement() {
            let buttonElement = 'button';

            if (this.routerLink && !this.disabledLink) {
                buttonElement = 'routerLink';
            } else if (this.isStatic) {
                buttonElement = 'div';
            } else if (this.link || this.disabledLink) {
                buttonElement = 'a';
            }

            return buttonElement;
        },
    },
};
</script>

<style lang="stylus">
:root {
    --nebula-button-background: $nebula-color-platform-interactive-850;
    --nebula-button-background-hover: $nebula-color-platform-interactive-900;
    --nebula-button-background-active: $nebula-color-platform-interactive-1000;
    --nebula-button-background-disabled: rgba($nebula-color-platform-interactive-850, .3);
    --nebula-button-border: none;
    --nebula-button-border-color: $nebula-color-platform-interactive-850;
    --nebula-button-border-color-hover: $nebula-color-platform-interactive-900;
    --nebula-button-border-color-active: $nebula-color-platform-interactive-1000;
    --nebula-button-border-color-disabled: rgba($nebula-color-platform-interactive-850, .3);
    --nebula-button-border-radius: $nebula-border-radius-button-default;
    --nebula-button-font-family: $nebula-font-family-body;
    --nebula-button-font-size: $nebula-font-size-body-1;
    --nebula-button-font-style: normal;
    --nebula-button-font-weight: 600;
    --nebula-button-line-height: 1;
    --nebula-button-padding: 11px $nebula-space-2x;
    --nebula-button-text-color: $nebula-color-platform-white;
    --nebula-button-text-color-hover: $nebula-color-platform-white;
    --nebula-button-text-color-active: $nebula-color-platform-white;
    --nebula-button-text-color-disabled: $nebula-color-platform-white;
    --nebula-button-transition: all $nebula-transition-default;
}

.nebula-button {
    align-items: center;
    background: var(--nebula-button-background, $nebula-color-platform-interactive-850);
    border: var(--nebula-button-border, none);
    border-color: var(--nebula-button-border-color, $nebula-color-platform-interactive-850);
    border-radius: var(--nebula-button-border-radius, $nebula-border-radius-button-default);
    color: var(--nebula-button-text-color, var(--nebula-color-platform-white));
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    font-family: var(--nebula-button-font-family, $nebula-font-family-body);
    font-size: var(--nebula-button-font-size, $nebula-font-size-body-1);
    font-style: var(--nebula-button-font-style, normal);
    font-weight: var(--nebula-button-font-weight, 600);
    line-height: var(--nebula-button-line-height, 1);
    padding: var(--nebula-button-padding, 11px $nebula-space-2x);
    text-decoration: none;
    transition: var(--nebula-button-transition, all $nebula-transition-default);
    width: max-content;

    .nebula-button__icon {
        fill: var(--nebula-button-text-color, $nebula-color-platform-white);
        height: 18px;
        transition: var(--nebula-button-transition, all $nebula-transition-default);
        vertical-align: middle;
        width: 18px;
    }

    &.nebula-button--icon-only {
        --nebula-button-padding: 11px;
    }

    &.nebula-button--ghost {
        --nebula-button-padding: 10px $nebula-space-2x; // Reduce padding by 1 to account for border

        &.nebula-button--icon-only {
            --nebula-button-padding: 10px; // Reduce padding by 1 to account for border
        }
    }

    /* Placed here before other pseudo-classes as other pseudo-classes should override 'visited'. */
    &:visited {
        color: var(--nebula-button-text-color, $nebula-color-platform-white);
    }

    &:hover {
        cursor: pointer;
    }

    &:hover,
    &:focus-visible {
        background: var(--nebula-button-background-hover, $nebula-color-platform-interactive-900);
        border-color: var(--nebula-button-border-color-hover, $nebula-color-platform-interactive-900);
        color: var(--nebula-button-text-color-hover, $nebula-color-platform-white);

        .nebula-button__icon {
            fill: var(--nebula-button-text-color-hover, $nebula-color-platform-white);
        }
    }

    &:active {
        background: var(--nebula-button-background-active, $nebula-color-platform-interactive-1000);
        border-color: var(--nebula-button-border-color-active, $nebula-color-platform-interactive-1000);
        color: var(--nebula-button-text-color-active, $nebula-color-platform-white);

        .nebula-button__icon {
            fill: var(--nebula-button-text-color-active, $nebula-color-platform-white);
        }
    }

    &:disabled,
    &[aria-disabled='true'] {
        background: var(--nebula-button-background-disabled, rgba($nebula-color-platform-interactive-850, .3));
        border-color: var(--nebula-button-border-color-disabled, rgba($nebula-color-platform-interactive-850, .3));
        color: var(--nebula-button-text-color-disabled, $nebula-color-platform-white);
        pointer-events: none;

        .nebula-button__icon {
            fill: var(--nebula-button-text-color-disabled, $nebula-color-platform-white);
        }
    }
}

.nebula-button--xs {
    font-size: $nebula-font-size-caption;
    --nebula-button-padding: 5px $nebula-space-1x;

    .nebula-button__icon {
        nebula-icon-s();
    }

    &.nebula-button--icon-only {
        --nebula-button-padding: $nebula-space-half;
    }

    &.nebula-button--ghost {
        --nebula-button-padding: 3px $nebula-space-1x; // Reduce padding by 1 to account for border

        &.nebula-button--icon-only {
            --nebula-button-padding: 3px; // Reduce padding by 1 to account for border
        }
    }
}

.nebula-button--s {
    --nebula-button-font-size: $nebula-font-size-body-2;
    --nebula-button-padding: $nebula-space-1x $nebula-space-2x;

    .nebula-button__icon {
        nebula-icon-s();
    }

    &.nebula-button--icon-only {
        --nebula-button-padding: $nebula-space-1x;
    }

    &.nebula-button--ghost {
        --nebula-button-padding: 7px $nebula-space-2x; // Reduce padding by 1 to account for border

        &.nebula-button--icon-only {
            --nebula-button-padding: 7px; // Reduce padding by 1 to account for border
        }
    }
}

.nebula-button--l {
    --nebula-button-padding: 15px $nebula-space-3x;

    .nebula-button__icon {
        height: 18px;
        width: 18px;
    }

    &.nebula-button--icon-only {
        --nebula-button-padding: 15px;
    }

    &.nebula-button--ghost {
        --nebula-button-padding: 14px $nebula-space-3x;; // Reduce padding by 1 to account for border

        &.nebula-button--icon-only {
            --nebula-button-padding: 14px; // Reduce padding by 1 to accound for border
        }
    }
}

.nebula-button--pill {
    --nebula-button-border-radius: $nebula-border-radius-button-pill;
}

.nebula-button--ghost {
    --nebula-button-background: transparent;
    --nebula-button-background-hover: $nebula-color-platform-interactive-900;
    --nebula-button-background-active: $nebula-color-platform-interactive-1000;
    --nebula-button-background-disabled: transparent;
    --nebula-button-border: 1px solid;
    --nebula-button-text-color: $nebula-color-platform-interactive-850;
    --nebula-button-text-color-hover: $nebula-color-platform-white;
    --nebula-button-text-color-active: $nebula-color-platform-white;
    --nebula-button-text-color-disabled: rgba($nebula-color-platform-interactive-850, .3);
}

.nebula-button--flat {
    --nebula-button-background: transparent;
    --nebula-button-background-hover: $nebula-color-platform-interactive-200;
    --nebula-button-background-active: $nebula-color-platform-interactive-400;
    --nebula-button-background-disabled: transparent;
    --nebula-button-text-color: $nebula-color-platform-interactive-850;
    --nebula-button-text-color-hover: $nebula-color-platform-interactive-900;
    --nebula-button-text-color-active: $nebula-color-platform-interactive-900;
    --nebula-button-text-color-disabled: rgba($nebula-color-platform-interactive-850, .3);
}

.nebula-button__text {
    vertical-align: middle;

    .nebula-button--icon-left & {
        margin-inline-start: $nebula-space-1x;
    }

    .nebula-button--icon-right & {
        margin-inline-end: $nebula-space-1x;
    }
}
</style>
