<template>
    <component
        class="section__header"
        :id="formattedId"
        :is="element"
        v-text="text"
    />
</template>

<script>

import { kebabCase } from 'lodash-es';

export default {
    name: 'SidebarSectionHeader',
    props: {
        element: {
            type: String,
            default: 'div',
        },
        id: {
            type: String,
        },
        text: {
            type: String,
        },
    },
    computed: {
        formattedId() {
            if (this.$props.id) {
                return this.$props.id;
            }
            return `nebula-sidebar-header--${kebabCase(this.text.toLowerCase())}`;
        },
    },
};
</script>
