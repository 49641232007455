<template>
    <div class="nebula-title-tooltip__container">
        <div
            class="nebula-title-tooltip__trigger"
            @keyup="triggerMouseOver()"
            @mouseover="triggerMouseOver()"
        >
            <slot />
        </div>
        <div
            class="nebula-title-tooltip"
            :class="usesLightTheme && 'nebula-title-tooltip--light'"
            :data-position="position"
            aria-hidden="true"
            ref="nebulaTitleTooltip"
            :style="toolTipStyle"
        >
            <p class="nebula-title-tooltip__text">
                {{ text }}
            </p>
            <span class="nebula-title-tooltip__nub" :style="toolTipNubStyle" />
        </div>
    </div>
</template>

<script>
import { getBoundingClientRect } from '@/utils/getBoundingClientRect';

export default {
    name: 'NebulaTitleTooltip',
    props: {
        text: String,
        id: String,
        usesLightTheme: {
            type: Boolean,
            default: false,
        },
        nub: {
            type: Boolean,
            default: true,
        },
        position: {
            type: String,
            default: 'block-start', // Options: block-start, block-end, inline-start, inline-end
            enum: ['block-start', 'block-end', 'block-end', 'inline-start', 'inline-end'], // for docs site
        },
    },
    data() {
        return {
            toolTipStyle: {},
            toolTipNubStyle: {},
        };
    },
    methods: {
        triggerMouseOver() {
            this.toolTipStyle = {};
            this.toolTipNubStyle = {};

            this.$nextTick(() => {
                const isRtl = getComputedStyle(this.$el).getPropertyValue('direction') === 'rtl';
                const { right, left } = getBoundingClientRect(this.$refs.nebulaTitleTooltip);

                // only applicable to tooltip positioned top/block-start or bottom/block-end
                if (this.position === 'block-start' || this.position === 'block-end') {
                    if (isRtl) {
                        // if the tooltip is bleeding out of the page to the right, adjust position
                        if (right > (document.documentElement.clientWidth)) {
                            this.toolTipStyle = {
                                'inset-inline-start': `calc(50% + ${right - window.innerWidth + 16}px)`,
                            };
                            this.toolTipNubStyle = {
                                'inset-inline-start': `calc(50% - ${right - window.innerWidth + 16}px)`,
                            };
                        } else if (left < 0) {
                            // if the tooltip is bleeding out of the page to the left, adjust position
                            this.toolTipStyle = {
                                'inset-inline-start': `calc(50% + ${left - 16}px)`,
                            };
                            this.toolTipNubStyle = {
                                'inset-inline-start': `calc(50% - ${left - 16}px)`,
                            };
                        }

                        return;
                    }

                    // if the tooltip is bleeding out of the page to the right, adjust position
                    if (right > (document.documentElement.clientWidth)) {
                        this.toolTipStyle = {
                            'inset-inline-start': `calc(50% - ${right - window.innerWidth + 16}px)`,
                        };
                        this.toolTipNubStyle = {
                            'inset-inline-start': `calc(50% + ${right - window.innerWidth + 16}px)`,
                        };
                    } else if (left < 0) {
                        // if the tooltip is bleeding out of the page to the left, adjust position
                        this.toolTipStyle = {
                            'inset-inline-start': `calc(50% - ${left - 16}px)`,
                        };
                        this.toolTipNubStyle = {
                            'inset-inline-start': `calc(50% + ${left - 16}px)`,
                        };
                    }
                }
            });
        },
    },
};
</script>

<style lang="stylus">
    :root {
        --nebula-tooltip-font-size: $nebula-font-size-caption;
        --nebula-tooltip-font-weight: 600;
        --nebula-tooltip-offset: $nebula-space-1x;
    }
    .nebula-title-tooltip__container {
        height: max-content;
        position: relative;
        width: max-content;
    }

    .nebula-title-tooltip {
        nebula-text-caption();
        background: $nebula-color-interface-blue-600;
        border-radius: $nebula-border-radius-button-default;
        color: $nebula-color-white;
        display: none; // hide tooltips below tablet resolution
        font-size: var(--nebula-tooltip-font-size);
        font-weight: var(--nebula-tooltip-font-weight);
        opacity: 0;
        padding: $nebula-space-1x $nebula-space-2x;
        position: absolute;
        transition: opacity $nebula-transition-default;
        visibility: hidden;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: inline-block; // only show tooltips above tablet resolution
        }

        &--light {
            background: $nebula-color-white;
            border: 1px solid $nebula-color-platform-interface-1000;
            color: $nebula-color-platform-neutral-900;

            .nebula-title-tooltip__nub {
                background: $nebula-color-white;
                border-color: $nebula-color-platform-interface-1000;
                border-style: solid;
                border-width: 1px 0 0 1px;
            }
        }

        .nebula-title-tooltip__trigger:hover + &,
        .nebula-title-tooltip__trigger:focus-within + & {
            opacity: 100;
            visibility: visible;
        }

        &[data-position="block-start"] {
            inset-block-end: 'calc(100% + %s)' % var(--nebula-tooltip-offset);
            inset-inline-start: 50%;
            transform: translateX(-50%);

            [dir="rtl"] & {
                transform: translateX(50%);
            }
        }

        &[data-position="block-end"] {
            inset-block-start: 'calc(100% + %s)' % var(--nebula-tooltip-offset);
            inset-inline-start: 50%;
            transform: translateX(-50%);

            [dir="rtl"] & {
                transform: translateX(50%);
            }
        }

        &[data-position="inline-start"] {
            inset-block-start: 50%;
            inset-inline-end: 'calc(100% + %s)' % var(--nebula-tooltip-offset);
            transform: translateY(-50%);

            [dir="rtl"] & {
                transform: translateY(50%);
            }
        }

        &[data-position="inline-end"] {
            inset-block-start: 50%;
            inset-inline-start: 'calc(100% + %s)' % var(--nebula-tooltip-offset);
            transform: translateY(-50%);

            [dir="rtl"] & {
                transform: translateY(50%);
            }
        }
    }

    .nebula-title-tooltip__text {
        display: none; // prevents unpositioned, invisible tooltips from causing scrollbars because text is bleeding off the page
        margin: 0;
        width: max-content;
    }

    .nebula-title-tooltip__trigger:hover + .nebula-title-tooltip,
    .nebula-title-tooltip__trigger:focus-within + .nebula-title-tooltip {
        .nebula-title-tooltip__text {
            display: inline-block;
        }
    }

    .nebula-title-tooltip__nub {
        background: $nebula-color-interface-blue-600;
        height: 10px;
        position: absolute;
        width: 10px;

        [data-position="block-start"] & {
            border-radius: 0 0 2px 0;
            inset-block-end: -5px;
            inset-inline-start: 50%;
            transform: translateX(-50%) rotate(45deg);

            [dir="rtl"] & {
                transform: translateX(50%) rotate(45deg);
            }
        }

        [data-position="block-end"] & {
            border-radius: 2px 0 0 0;
            inset-block-start: -5px;
            inset-inline-start: 50%;
            transform: translateX(-50%) rotate(45deg);

            [dir="rtl"] & {
                transform: translateX(50%) rotate(45deg);
            }
        }

        [data-position="inline-start"] & {
            border-radius: 0 2px 0 0;
            inset-block-start: 50%;
            inset-inline-end: -5px;
            transform: translateY(-50%) rotate(45deg);

            [dir="rtl"] & {
                transform: translateY(50%) rotate(45deg);
            }
        }

        [data-position="inline-end"] & {
            border-radius: 0 0 0 2px;
            inset-block-start: 50%;
            inset-inline-start: -5px;
            transform: translateY(-50%) rotate(45deg);

            [dir="rtl"] & {
                transform: translateY(50%) rotate(45deg);
            }
        }
    }
</style>
