<template>
    <li
        class="section__list-item"
        :class="sidebarItemClass"
        :data-name="$t(item.name)"
        :id="formattedId"
    >
        <NebulaButton
            :link="item.url"
            :text="$t(item.name)"
            :icon-left="item.icon ? item.icon : ''"
            :title="useTitleAttributeOnItems ? item.name : null"
            type="flat"
            size="s"
            :class="item.usePushState ? 'do-push-state' : ''"
            :routerLink="item.routerLink"
            @click="$emit('sidebarItemClick')"
        />
    </li>
</template>

<script>

import { randomStringId } from '@/utils/randomString';
import NebulaButton from '@/components/Button/NebulaButton.vue';

export default {
    name: 'SidebarItem',
    components: {
        NebulaButton,
    },
    props: {
        id: {
            type: String,
        },
        item: {
            type: Object,
        },
        useTitleAttributeOnItems: {
            default: false,
            type: Boolean,
        },
    },
    emits: ['sidebarItemClick'],
    data() {
        return {
            sidebarCollapsed: false,
        };
    },
    computed: {
        formattedId() {
            if (this.$props.id) {
                return this.$props.id;
            }
            return `nebula-sidebar-item--${this.randomStringId}`;
        },
        randomStringId() {
            return randomStringId();
        },
        sidebarItemClass() {
            const item = JSON.parse(JSON.stringify(this.item));
            const className = [
                'nebula-sidebar__accordion-item',
                {
                    'nebula-sidebar__accordion-item--current': item.active && item.active === true,
                    'nebula-sidebar__accordion-item--hidden': item.hidden && item.hidden === true,
                },
            ];
            return className;
        },
    },
};
</script>

<style lang="stylus">

</style>
