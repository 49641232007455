<template>
    <component
        :is="element"
        :class="getSidebarClass"
        :style="cssProps"
    >
        <NebulaButton
            class="nebula-sidebar__toggle-nav-button"
            :aria-label="getAriaLabel"
            :aria-controls="sidebarId"
            :aria-expanded="sidebarOpen.toString()"
            :iconRight="sidebarCollapsed ? 'sidebar-expand' : 'sidebar-collapse'"
            shape="pill"
            size="s"
            :text="getResponsiveButtonText"
            @click="handleCollapse"
        />
        <div
            class="nebula-sidebar__inner"
            :id="sidebarId"
        >
            <slot name="sidebar-top" />
            <div
                v-if="sidebarData && useAccordionGroups"
                class="nebula-sidebar__section"
            >
                <NebulaAccordion
                    v-for="(section, index) in sidebarData"
                    :key="index"
                    element="div"
                    class="nebula-sidebar__accordion"
                    :text="$t(section.section_title)"
                    :isExpandedOnLoad="section.expanded"
                    mode="flat"
                >
                    <ul class="section__list">
                        <NebulaSidebarItem
                            v-for="(item, i) in section.items"
                            :key="i"
                            :item="item"
                            :class="getSidebarItemClass(item)"
                            :useTitleAttributeOnItems="useTitleAttributeOnItems"
                        />
                    </ul>
                </NebulaAccordion>
            </div>
            <div
                v-else-if="sidebarData && !useAccordionGroups"
                v-for="(section, index) in sidebarData"
                :key="index"
                class="nebula-sidebar__section"
            >
                <NebulaSidebarSectionHeader :text="$t(section.section_title)" />
                <ul class="section__list">
                    <NebulaSidebarItem
                        v-for="(item, i) in section.items"
                        :key="i"
                        :item="item"
                        :useTitleAttributeOnItems="useTitleAttributeOnItems"
                    />
                </ul>
            </div>
            <slot name="sidebar-content" />
            <slot name="sidebar-bottom" />
        </div>
    </component>
</template>

<script>
import { throttle } from 'lodash-es';
import { randomStringId } from '@/utils/randomString';
import NebulaAccordion from '@/components/Accordion/NebulaAccordion.vue';
import NebulaButton from '@/components/Button/NebulaButton.vue';
import NebulaSidebarItem from '@/components/Sidebar/NebulaSidebarItem.vue';
import NebulaSidebarSectionHeader from '@/components/Sidebar/NebulaSidebarSectionHeader.vue';
import themingUtils from '@/utils/theming/themingUtils';

export default {
    name: 'NebulaSidebar',
    mixins: [themingUtils],
    components: {
        NebulaAccordion,
        NebulaButton,
        NebulaSidebarItem,
        NebulaSidebarSectionHeader,
    },
    data() {
        return {
            sidebarCollapsed: false,
            windowSize: null,
        };
    },
    props: {
        ariaLabelCollapse: {
            type: String,
            default: null,
        },
        ariaLabelExpand: {
            type: String,
            default: null,
        },
        element: {
            type: String,
            default: 'div',
        },
        id: {
            type: String,
            default: null,
        },
        responsiveButtonTextClose: {
            type: String,
            default: null,
        },
        responsiveButtonTextOpen: {
            type: String,
            default: null,
        },
        responsiveThreshold: {
            type: Number,
            default: 600,
        },
        sidebarData: {
            type: Array,
        },
        transitionSpeed: {
            type: Number,
            default: 100,
        },
        useAccordionGroups: {
            type: Boolean,
            default: false,
        },
        useResponsiveCollapseOnload: {
            type: Boolean,
            default: false,
        },
        useTitleAttributeOnItems: {
            type: Boolean,
            default: false,
        },
        wrapLongSidebarItems: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.useResponsiveCollapseOnload) {
            this.throttledHandleResize = throttle(this.setWindowSize, 150);
            window.addEventListener('resize', this.throttledHandleResize);

            this.setWindowSize();
            if (this.responsiveCollapseActivated) {
                this.sidebarCollapsed = true;
            } else {
                this.sidebarCollapsed = false;
            }
        }
    },
    emits: ['sidebar-collapsed'],
    computed: {
        getAriaLabel() {
            let ariaLabel = '';

            if (this.sidebarCollapsed) {
                ariaLabel = this.ariaLabelExpand ? this.ariaLabelExpand : this.$t('expand sidebar');
            } else {
                ariaLabel = this.ariaLabelCollapse ? this.ariaLabelCollapse : this.$t('collapse sidebar');
            }

            return ariaLabel;
        },
        getResponsiveButtonText() {
            let buttonText = '';

            if (this.useResponsiveCollapseOnload) {
                if (this.responsiveCollapseActivated && this.sidebarCollapsed && this.responsiveButtonTextOpen) {
                    buttonText = this.responsiveButtonTextOpen;
                } else if (this.responsiveCollapseActivated && !this.sidebarCollapsed && this.responsiveButtonTextClose) {
                    buttonText = this.responsiveButtonTextClose;
                }
            }

            return buttonText;
        },
        getSidebarClass() {
            const className = [
                'nebula-sidebar',
                {
                    'nebula-sidebar--collapsed': this.sidebarCollapsed === true,
                    'nebula-sidebar--ellipses-long-sidebar-items': this.wrapLongSidebarItems === false,
                    'nebula-sidebar--show-responsive-button-text': this.responsiveCollapseActivated === true
                        && (this.responsiveButtonTextClose || this.responsiveButtonTextOpen),
                    'nebula-sidebar--wrap-long-sidebar-items': this.wrapLongSidebarItems === true,
                },
            ];
            return className;
        },
        randomStringId() {
            return randomStringId();
        },
        responsiveCollapseActivated() {
            return this.windowSize <= this.responsiveThreshold;
        },
        sidebarEl() {
            return document.getElementById(this.sidebarId);
        },
        sidebarId() {
            let sidebarId = '';

            if (this.id) {
                sidebarId = this.id;
            } else {
                sidebarId = `nebula-sidebar--${this.randomStringId}`;
            }

            return sidebarId;
        },
        sidebarOpen() {
            return !this.sidebarCollapsed;
        },
    },
    methods: {
        getSidebarItemClass(item) {
            const className = [
                'nebula-sidebar__accordion-item',
                {
                    'nebula-sidebar__accordion-item--current': item.active === true,
                    'nebula-sidebar__accordion-item--hidden': item.hidden === true,
                },
            ];
            return className;
        },
        handleCollapse() {
            this.sidebarCollapsed = !this.sidebarCollapsed;
            this.$emit('sidebar-collapsed', this.sidebarCollapsed);
        },
        setWindowSize() {
            this.windowSize = document.documentElement.clientWidth;
        },
    },
    watch: {
        responsiveCollapseActivated(newVal) {
            if (this.useResponsiveCollapseOnload) {
                if (newVal === true) {
                    this.sidebarCollapsed = true;
                } else {
                    this.sidebarCollapsed = false;
                }
            }
        },
        sidebarCollapsed(newVal) {
            if (newVal === true) {
                setTimeout(() => {
                    this.sidebarEl.classList.add('nebula-sidebar__inner--removed');
                }, this.transitionSpeed);
            } else {
                this.sidebarEl.classList.remove('nebula-sidebar__inner--removed');
            }
        },
    },
    beforeUnmount() {
        if (this.useResponsiveCollapseOnload) {
            window.removeEventListener('resize', this.throttledHandleResize);
        }
    },
};
</script>

<style lang="stylus">

:root {
    --nebula-sidebar-background: $nebula-color-platform-interactive-100;
    --nebula-sidebar-collapsed-negative-margin: -200px;
    --nebula-sidebar-item-background-active: $nebula-color-platform-interactive-300;
    --nebula-sidebar-item-background-current: $nebula-color-platform-interactive-400;
    --nebula-sidebar-item-background-hover: $nebula-color-platform-interactive-200;
    --nebula-sidebar-item-color: $nebula-color-platform-interactive-1000;
    --nebula-sidebar-item-color-current: $nebula-color-platform-interactive-1100;
    --nebula-sidebar-item-font-weight-current: 600;
    --nebula-sidebar-header-color: $nebula-text-color-header-default;
    --nebula-sidebar-header-font-size: $nebula-font-size-body-1;
    --nebula-sidebar-header-font-weight: 600;
    --nebula-sidebar-inner-padding: $nebula-space-2x;
    --nebula-sidebar-toggle-button-background: $nebula-color-white;
    --nebula-sidebar-toogle-button-box-shadow: $nebula-shadow-100;
    --nebula-sidebar-toggle-button-color: $nebula-color-interactive-blue-400;
    --nebula-sidebar-transition: margin $nebula-transition-default .1s;
    --nebula-sidebar-width: 225px;
}

.nebula-sidebar {
    background-color: var(--nebula-sidebar-background, $nebula-color-platform-interactive-100);
    height: 100vh;
    position: sticky;
    transition: var(--nebula-sidebar-transition, margin $nebula-transition-default .1s);
    top: 0;
    width: var(--nebula-sidebar-width, 225px);

    &.nebula-sidebar--collapsed {
        margin-inline-start: var(--nebula-sidebar-collapsed-negative-margin, -200px);
        transition: margin $nebula-transition-default;
    }

    &--ellipses-long-sidebar-items {
        .section__list .nebula-button .nebula-button__text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &--wrap-long-sidebar-items {
        .section__list .nebula-button .nebula-button__text {
            text-align: start;
        }
    }

    &__inner {
        background-color: unset;
        height: 100%;
        opacity: 1;
        overflow-y: auto;
        padding-block: $nebula-space-5x;
        padding-inline: var(--nebula-sidebar-inner-padding, $nebula-space-2x);
        transition: opacity .5s ease-in-out;

        .nebula-sidebar--collapsed & {
            opacity: 0;
        }

        &--removed {
            display: none;
        }
    }

    &__toggle-nav-button {
        --nebula-button-background: var(--nebula-sidebar-toggle-button-background, $nebula-color-white);
        --nebula-button-background-active: var(--nebula-sidebar-toggle-button-background, $nebula-color-white);
        --nebula-button-background-hover: var(--nebula-sidebar-toggle-button-background, $nebula-color-white);
        --nebula-button-text-color: var(--nebula-sidebar-toggle-button-color, $nebula-color-interactive-blue-400);
        --nebula-button-text-color-active: var(--nebula-sidebar-toggle-button-color, $nebula-color-interactive-blue-400);
        --nebula-button-text-color-hover: var(--nebula-sidebar-toggle-button-color, $nebula-color-interactive-blue-400);
        box-shadow: var(--nebula-sidebar-toogle-button-box-shadow, $nebula-shadow-100);
        inset-block-start: $nebula-space-2x;
        inset-inline-end: -16px;
        position: absolute;
        z-index: 10;
    }

    &.nebula-sidebar--show-responsive-button-text {
        .nebula-sidebar__toggle-nav-button {
            inset-inline-start: 92.5%;
            position: absolute;
        }
    }

    &__section {
        margin-block-end: $nebula-space-3x;
    }

    .section__header {
        color: var(--nebula-sidebar-header-color, $nebula-text-color-header-default);
        font-size: var(--nebula-sidebar-header-font-size, $nebula-font-size-body-1);
        font-weight: var(--nebula-sidebar-header-font-weight, 600);
        margin-inline-start: $nebula-space-2x;
    }

    .section__list {
        list-style: none;
        margin: 0;
        padding: 0;

        .nebula-button {
            --nebula-button-text-color: var(--nebula-sidebar-item-color, $nebula-color-platform-interactive-1000);
            --nebula-button-text-color-active: var(--nebula-sidebar-item-color, $nebula-color-platform-interactive-1000);
            --nebula-button-text-color-hover: var(--nebula-sidebar-item-color, $nebula-color-platform-interactive-1000);
            --nebula-button-background-active: var(--nebula-sidebar-item-background-active, $nebula-color-platform-interactive-300);
            --nebula-button-background-hover: var(--nebula-sidebar-item-background-hover, $nebula-color-platform-interactive-200);
            font-weight: 400;
            margin-block: $nebula-space-1x;
            padding-block: $nebula-space-1x;
            width: 100%;

            .nebula-button__icon {
                min-width: $nebula-space-2x;
            }

            .nebula-button__text {
                min-height: 17px;
            }
        }

        .section__list-item--current {
            .nebula-button {
                --nebula-button-background: var(--nebula-sidebar-item-background-current, $nebula-color-platform-interactive-400);
                --nebula-button-background-active: var(--nebula-sidebar-item-background-current,
                    $nebula-color-platform-interactive-400);
                --nebula-button-background-hover: var(--nebula-sidebar-item-background-current, $nebula-color-platform-interactive-400);
                --nebula-button-text-color: var(--nebula-sidebar-item-color-current, $nebula-color-platform-interactive-1000);
                --nebula-button-text-color-active: var(--nebula-sidebar-item-color-current, $nebula-color-platform-interactive-1000);
                --nebula-button-text-color-hover: var(--nebula-sidebar-item-color-current, $nebula-color-platform-interactive-1000);
                font-weight: var(--nebula-sidebar-item-font-weight-current, 600);
            }
        }
    }

    .nebula-sidebar__accordion {
        --nebula-accordion-background-color: transparent;
        --nebula-accordion-button-background-color: transparent;
        --nebula-accordion-button-text-color: var(--nebula-sidebar-item-color, $nebula-color-platform-interactive-900);
        --nebula-accordion-caret-color:  var(--nebula-sidebar-item-color, $nebula-color-platform-interactive-900);
        --nebula-accordion-expanded-background-color: transparent;
        --nebula-accordion-expanded-color: var(--nebula-sidebar-item-color, $nebula-color-platform-interactive-900);
        --nebula-accordion-non-outlined-background-color: transparent;

        &.nebula-accordion--flat {
            .nebula-accordion__button {
                border-radius: 0;
                padding-block: $nebula-space-1x;

                .nebula-accordion__title {
                    font-weight: var(--nebula-sidebar-header-font-weight, 600);
                }
            }

            .nebula-accordion__content-container {
                --nebula-accordion-padding: 0;

                .nebula-sidebar__accordion-item--current .nebula-button {
                    --nebula-button-background: var(--nebula-sidebar-item-background-current, $nebula-color-platform-interactive-400);
                    --nebula-button-background-active: var(--nebula-sidebar-item-background-current,
                        $nebula-color-platform-interactive-400);
                    --nebula-button-background-hover: var(--nebula-sidebar-item-background-current, $nebula-color-platform-interactive-400);
                    --nebula-button-text-color: var(--nebula-sidebar-item-color-current, $nebula-color-platform-interactive-1000);
                    --nebula-button-text-color-active: var(--nebula-sidebar-item-color-current, $nebula-color-platform-interactive-1000);
                    --nebula-button-text-color-hover: var(--nebula-sidebar-item-color-current, $nebula-color-platform-interactive-1000);
                    font-weight: var(--nebula-sidebar-item-font-weight-current, 600);
                }
            }
        }
    }

    .nebula-sidebar__accordion--hidden,
    .nebula-sidebar__accordion-item--hidden {
        display: none;
    }
}
</style>
